{
  "analytics": {
    "messages": {
      "noReportsFound": "No reports found"
    },
    "title": "Analytics"
  },
  "app": {
    "actions": {
      "postpone": "Postpone",
      "update": "Update"
    },
    "messages": {
      "newAppVersionIsAvailable": "New app version is available"
    }
  },
  "charts": {
    "axisLabels": {
      "days": "Days",
      "units": "Units"
    }
  },
  "common": {
    "actions": {
      "apply": "Apply",
      "avoid": "Avoid",
      "cancel": "Cancel",
      "clear": "Clear",
      "clearFilters": "Clear filters",
      "contactSupport": "Contact support",
      "download": "Download",
      "downloadChartImage": "Download chart image",
      "downloadPickingList": "Download picking list",
      "goToHomePage": "Go to home page",
      "openColumnsVisibilitySettings": "Columns",
      "openFilters": "Filter",
      "openMetricsVisibilitySettings": "Metrics",
      "openSortSettings": "Sort",
      "reload": "Reload",
      "save": "Save",
      "seeSkuChart": "See SKU history chart",
      "undo": "Undo",
      "viewDetails": "See details",
      "viewLess": "View less",
      "viewMore": "View more"
    },
    "errors": {
      "badRequest": "Bad request",
      "loadDataFailed": "Data failed to load",
      "notFound": "Not found"
    },
    "messages": {
      "columnsSearchHint": "Search columns",
      "columnsVisibilitySelectionHint": "Select columns to display",
      "filteringUnavailableDueToDataIndexing": "Data indexing is in progress. Filtering is temporary unavailable.",
      "metricsSearchHint": "Search metrics",
      "metricsVisibilitySelectionHint": "Select metrics to display",
      "pageNotFound": "Page not found",
      "searchHint": "Search",
      "searchingUnavailableDueToDataIndexing": "Data indexing is in progress. Searching is temporary unavailable."
    },
    "plurals": {
      "days": "day | days",
      "items": "item | items",
      "months": "month | months"
    },
    "statuses": {
      "lastUpdateTimeAgo": "Updated {timeAgo}",
      "loading": "Loading",
      "noColumnsFound": "No columns found",
      "noData": "No data",
      "noMetricsFound": "No metrics found",
      "noMetricsSelected": "No metrics selected"
    },
    "values": {
      "all": "All",
      "no": "No",
      "yes": "Yes"
    }
  },
  "globalNavigation": {
    "analytics": "Analytics",
    "home": "Home",
    "inventory": "Inventory",
    "replenishment": "Replenishment",
    "specialEvents": "Special Events",
    "storeTransfer": "Store Transfer"
  },
  "insufficientReplenishment": {
    "datasetsNames": {
      "replenishment": "Replenishment",
      "salesRate": "Sales rate",
      "stock": "Stock"
    },
    "properties": {
      "brand": "Brand",
      "category": "Category",
      "color": "Color",
      "country": "Country",
      "creationDate": "Creation date",
      "department": "Department",
      "price": "Price",
      "product": "Product",
      "region": "Region",
      "replenishment": "Replenishment",
      "replenishmentTime": "Replenishment time",
      "season": "Season",
      "size": "Size",
      "style": "Style",
      "supply": "Supply",
      "warehouse": "WH",
      "warehouseId": "WH ID"
    },
    "title": "Insufficient replenishment"
  },
  "inventory": {
    "common": {
      "actions": {
        "avoidReplenishment": "Avoid replenishment",
        "unavoidReplenishment": "Unavoid replenishment"
      },
      "messages": {
        "avoidReplenishmentConfirmation": "Replenishment for the selected items will be stopped. Are you sure you want to avoid it?",
        "tryAgainLater": "Try again later"
      },
      "properties": {
        "department": "Department",
        "departmentId": "Department ID",
        "description": "Description",
        "location": "Location",
        "locationId": "Location ID",
        "product": "Product",
        "productId": "Product ID",
        "sku": "SKU",
        "skuId": "SKU ID"
      },
      "statuses": {
        "avoidReplenshimentFailed": "Failed to Avoid Replenshiment."
      }
    },
    "locations": {
      "kpi": {
        "inventoryCost": "Inventory cost",
        "plants": "Plants",
        "stores": "Stores",
        "suppliers": "Suppliers",
        "totalInventory": "Inventory",
        "totalLocations": "Locations",
        "vwarehouses": "Virtual warehouses",
        "warehouses": "Warehouses"
      },
      "properties": {
        "brand": "Brand",
        "city": "City",
        "classification": "Classification",
        "creationDate": "Creation date",
        "inventoryAtSite": "At site",
        "inventoryCost": "Inventory cost",
        "inventoryInTransit": "In transit",
        "inventoryPrice": "Inventory price",
        "inventoryReserved": "Reserved",
        "locationType": "Location type",
        "name": "Name",
        "numberOfProducts": "# products",
        "numberOfSkus": "# SKUs",
        "region": "Region",
        "replenishmentAvoided": "Avoid replenishment"
      },
      "statuses": {
        "loadKpisFailed": "Failed to load KPIs.",
        "noLocationsFound": "No locations found"
      },
      "title": "Location"
    },
    "skuChart": {
      "actions": {
        "downloadSkuHistoryReport": "Download SKU History report (CSV)"
      },
      "metrics": {
        "coverage": "Coverage",
        "inventoryAtSite": "Inventory at site",
        "inventoryAtWarehouse": "At WH",
        "inventoryInTransit": "In transit",
        "inventoryReserved": "Reserved",
        "optimalStock": "Optimal stock",
        "replenishmentRecommendation": "Replenishment recommendation",
        "salesRate": "Sales rate",
        "surplus": "Surplus",
        "transactionsIn": "In",
        "transactionsOut": "Out",
        "transactionsSale": "Sale"
      },
      "metricsGroups": {
        "calculations": "Calculations",
        "inventory": "Inventory level",
        "transactions": "Transactions"
      },
      "properties": {
        "brand": "Brand",
        "catalogPrice": "Catalog price",
        "category": "Category",
        "city": "City",
        "color": "Color",
        "cost": "Cost",
        "creationDate": "Creation date",
        "department": "Department",
        "departmentId": "Department ID",
        "location": "Location",
        "locationBrand": "Location brand",
        "locationClassification": "Location classification",
        "locationId": "Location ID",
        "locationType": "Location type",
        "product": "Product",
        "productId": "Product ID",
        "region": "Region",
        "replenishmentTime": "Replenishment time",
        "season": "Season",
        "size": "Size",
        "sku": "SKU",
        "skuId": "SKU ID",
        "source": "Source",
        "sourceId": "Source ID",
        "style": "Style",
        "targetMarket": "Target market",
        "updateDate": "Update date"
      },
      "title": "SKU history chart"
    },
    "skus": {
      "kpi": {
        "dailySales": "Daily sales",
        "totalCategories": "Categories",
        "totalInventory": "Inventory",
        "totalProducts": "Products",
        "totalSkus": "SKUs"
      },
      "properties": {
        "brand": "Brand",
        "category": "Category",
        "color": "Color",
        "cost": "Cost",
        "coverage": "Coverage",
        "creationDate": "Creation date",
        "department": "Department",
        "departmentId": "Department ID",
        "departmentName": "Department name",
        "market": "Market",
        "numberOfStores": "# stores",
        "packConstraint": "Pack constraint",
        "price": "Price",
        "product": "Product",
        "quantityAtSite": "At site",
        "quantityAtWarehouse": "At WH",
        "quantityInTransit": "In transit",
        "replenishmentAvoided": "Avoid replenishment",
        "reservedQuantity": "Reserved",
        "salesRate": "Sales rate",
        "season": "Season",
        "size": "Size",
        "skuId": "SKU ID",
        "skuName": "SKU name",
        "style": "Style"
      },
      "statuses": {
        "noSkusFound": "No SKUs found"
      },
      "title": "SKU"
    },
    "skusLocations": {
      "kpi": {
        "totalInventory": "Inventory",
        "totalLocations": "Locations",
        "totalSkus": "SKUs"
      },
      "properties": {
        "brand": "Brand",
        "category": "Category",
        "city": "City",
        "classification": "Classification",
        "color": "Color",
        "cost": "Cost",
        "coverage": "Coverage",
        "creationDate": "Creation date",
        "departmentId": "Department ID",
        "departmentName": "Department name",
        "location": "Location",
        "locationDescription": "Location description",
        "locationType": "Location type",
        "market": "Market",
        "minStock": "Min stock",
        "optimalStock": "Optimal stock",
        "price": "Price",
        "product": "Product",
        "quantityAtSite": "At site",
        "quantityAtWarehouse": "At WH",
        "quantityInTransit": "In transit",
        "region": "Region",
        "replenishmentAvoided": "Avoid replenishment",
        "replenishmentAvoidedForLocation": "Avoid replenishment (location)",
        "replenishmentAvoidedForSku": "Avoid replenishment (SKU)",
        "reservedQuantity": "Reserved",
        "salesRate": "Sales rate",
        "season": "Season",
        "size": "Size",
        "sku": "SKU",
        "skuDescription": "SKU description",
        "skuId": "SKU ID",
        "skuName": "SKU name",
        "style": "Style",
        "warehouseName": "WH name"
      },
      "statuses": {
        "noInventoriesFound": "No inventories found"
      },
      "title": "SKU-Location"
    },
    "title": "Inventory"
  },
  "replenishment": {
    "common": {
      "actions": {
        "downloadReplenishmentPickingList": "Download replenishment picking list (CSV)"
      },
      "kpi": {
        "products": "Products",
        "replenishment": "Replenishment",
        "skus": "SKU",
        "stores": "Stores"
      },
      "statuses": {
        "loadKpisFailed": "Failed to load KPIs.",
        "pickingListLoading": "Replenishment picking list is loading",
        "pickingListLoadingComplete": "Replenishment picking list loading complete",
        "pickingListLoadingFailed": "Replenishment picking list loading failed"
      }
    },
    "constraints": {
      "names": {
        "depleted": "Depleted WH",
        "insufficient": "Insufficient supply",
        "package": "Package constraint",
        "shipmentSchedule": "Schedule constraint"
      }
    },
    "inventories": {
      "properties": {
        "expectedCoverage": "Expected coverage",
        "inventoryNeeded": "Inventory needed",
        "lastSiteQuantity": "Last site quantity",
        "lastTransitQuantity": "Last transit quantity",
        "quantityAtWarehouse": "Quantity at warehouse",
        "replenishment": "Replenishment",
        "replenishmentAdjustment": "Replenishment adjustment",
        "saleRate": "Sale rate",
        "stock": "Stock"
      },
      "title": "SKU-Location"
    },
    "messages": {
      "groupAvoidReplenishmentSuccess": "Avoided replenishment for {n} @:common.plurals.items",
      "groupUnavoidReplenishmentSuccess": "Unavoided replenishment for {n} @:common.plurals.items"
    },
    "overview": {
      "actions": {
        "downloadReplenishmentOverview": "Download replenishment overview (CSV)"
      },
      "properties": {
        "averageDailySales": "AVG daily sales",
        "averageDailySalesDepleted": "AVG daily sales n/a at WH",
        "coverage": "Coverage",
        "coverageDepleted": "Coverage n/a at WH",
        "expectedCoverage": "Expected coverage",
        "replenishment": "Replenishment",
        "sold": "Sold",
        "soldDepleted": "Sold n/a at WH"
      },
      "title": "Overview"
    },
    "products": {
      "actions": {
        "seeProductDetails": "Details"
      },
      "description": {
        "constraints": "Shipment constraints that affected the replenishment recommendation.",
        "coverage": "The number of days the current inventory of the SKU at the store can sustain. Assuming current demand remains relatively steady, expected sales are calculated according to the current sales rate",
        "expectedCoverage": "The number of days the replenished inventory of the SKU at the store is expected to sustain. Assuming current demand remains relatively steady, expected sales are calculated according to the current sales rate",
        "numberOfStores": "The number of stores expected to receive replenishment for the SKU.",
        "optimalStock": "The optimal inventory level for the SKU at the store",
        "replenishment": "The number of units recommended for the SKU replenishment to the store.",
        "replenishmentTime": "The time it takes for replenished items to reach the store’s shelves.",
        "salesRate": "The average number of units sold per day, on days the SKU was available for shoppers at the store.",
        "sold": "The number of units the SKU sold since the last time it received replenishment at the store.",
        "stock": "The current inventory level at the store, including inventory that is already on route. Hover on a store SKU’s stock to view the inventory breakdown.",
        "warehouseInventory": "The number of units currently available at the supplying warehouse (prior to today’s replenishment).",
        "warehouseName": "The name of the warehouse sending replenishment for the SKU at the store."
      },
      "properties": {
        "brand": "Brand",
        "category": "Category",
        "color": "Color",
        "constraints": "Constraints",
        "coverage": "Coverage",
        "expectedCoverage": "Expected coverage",
        "numberOfStores": "# stores",
        "optimalStock": "Optimal stock",
        "productId": "Product ID",
        "replenishment": "Replenishment",
        "replenishmentTime": "Replenishment time",
        "salesRate": "Sales rate",
        "season": "Season",
        "size": "Size",
        "sku": "SKU",
        "skuId": "SKU ID",
        "sold": "Sold",
        "stock": "Stock",
        "storeId": "Store ID",
        "style": "Style",
        "targetMarket": "Target market",
        "totalReplenishment": "Replenishment",
        "unitCost": "Unit cost",
        "unitPrice": "Unit price",
        "warehouseInventory": "WH inventory",
        "warehouseName": "WH name"
      },
      "statuses": {
        "noProductsFound": "No products found",
        "productNotFound": "Product not found"
      },
      "title": "Product"
    },
    "stores": {
      "actions": {
        "downloadReplenishmentReport": "Download replenishment report"
      },
      "description": {
        "averageDailySales": "The average daily expected income generated by the store’s SKUs (that have available supply at the WH). Calculated according to the catalog price",
        "averageDailySalesDepleted": "The average daily expected income generated by the store’s SKUs (that do not have available supply at the WH). Calculated according to the current sales rate and catalog price.",
        "constraints": "Shipment constraints that affected the replenishment recommendation.",
        "coverage": "The number of days the current inventory of the store’s SKUs (that have available supply at the WH) can sustain. Assuming current demand remains relatively steady, expected sales are calculated according to the current sales rate and catalog price.",
        "coverageDepleted": "The number of days the current inventory of the store’s SKUs that do not have available supply at the WH can sustain until it runs out of stock, assuming current demand remains relatively steady.",
        "expectedCoverage": "The number of days the replenished inventory of the store’s SKUs is expected to sustain. Assuming current demand remains relatively steady, expected sales are calculated according to the current sales rate and catalog price.",
        "numberOfProducts": "The number of different products that are expected to receive replenishment.",
        "numberOfSkus": "The number of different SKUs (product-size) that are expected to receive replenishment",
        "replenishment": "The total number of units recommended for replenishment to the store.",
        "replenishmentTime": "The time it takes for replenished items to reach the store’s shelves.",
        "sold": "The number of units sold by SKUs (that have available supply at the WH) since the last time each SKU was replenished at the store.",
        "soldDepleted": "The number of units sold by SKUs (that need replenishment but do not have available supply at the WH) since the last time each SKU was replenished at the store.",
        "warehouse": "The name of the warehouse sending replenishment to the store"
      },
      "messages": {
        "searchByProductHint": "Search by product"
      },
      "properties": {
        "averageDailySales": "AVG daily sales",
        "averageDailySalesDepleted": "AVG daily sales n/a at WH",
        "brand": "Brand",
        "city": "City",
        "classification": "Classification",
        "constraints": "Constraints",
        "coverage": "Coverage",
        "coverageDepleted": "Coverage n/a at WH",
        "expectedCoverage": "Expected coverage",
        "locationType": "Location type",
        "numberOfProducts": "# products",
        "numberOfSkus": "# SKUs",
        "region": "Region",
        "replenishment": "Replenishment",
        "replenishmentTime": "Replenishment time",
        "sold": "Sold",
        "soldDepleted": "Sold n/a at WH",
        "store": "Store",
        "storeId": "Store ID",
        "warehouseName": "WH name"
      },
      "statuses": {
        "noStoresFound": "No stores found",
        "storeNotFound": "Store not found"
      },
      "storeSummaryProperties": {
        "numberOfProducts": "# products",
        "numberOfSkus": "# SKUs",
        "sold": "Sold",
        "totalReplenishment": "Replenishment"
      },
      "title": "Store"
    },
    "title": "Replenishment"
  },
  "storeTransfer": {
    "common": {
      "messages": {
        "clustersDownloadingInProgress": "Picking list is loading",
        "pickingListDownloadingComplete": "Picking list loading complete",
        "pickingListDownloadingFailed": "Picking list loading complete"
      },
      "properties": {
        "brand": "Brand",
        "catalogPrice": "Catalog price",
        "category": "Category",
        "color": "Color",
        "cost": "Cost",
        "department": "Department",
        "esi": "Estimated Sales Increase",
        "esiShort": "ESI",
        "productId": "Product ID",
        "season": "Season",
        "style": "Style",
        "targetMarket": "Target market",
        "totalProducts": "Products",
        "totalStores": "Stores",
        "totalTrips": "Trips",
        "totalUnits": "Units"
      },
      "statuses": {
        "clusterNotFound": "Cluster not found"
      }
    },
    "navigation": {
      "recommendations": "Recommendations",
      "transfers": "Your transfers"
    },
    "plurals": {
      "cluster": "cluster | clusters"
    },
    "recommendations": {
      "actions": {
        "approve": "Approve",
        "approveAll": "Approve all",
        "downloadPickingList": "Download picking list",
        "recover": "Recover",
        "recoverRejectedClusters": "Recover rejected clusters",
        "reject": "Reject",
        "seeTripDetails": "See trip details",
        "undoApproval": "Undo approval"
      },
      "allClusters": "All clusters",
      "approvedClusters": "Approved clusters",
      "clusterProperties": {
        "esi": "ESI",
        "stores": "Stores",
        "trips": "Trips",
        "units": "Units"
      },
      "messages": {
        "allClustersApproved": "All clusters have been approved",
        "approvedClustersRecoveryText": "Recover approved cluster recommendations.",
        "calculationInProgressText": "{completed} of {total} clusters are ready",
        "calculationInProgressTitle": "Calculation is in progress",
        "calculationInactiveText": "To start using Store Transfer you need to set up store clusters.",
        "calculationInactiveTitle": "Start moving around",
        "clusterApprovalFailed": "Failed to approve clusters",
        "clusterApprovalSuccess": "{clusterName} cluster has been approved",
        "clusterApprovalUndoFailed": "Failed to undo clusters approval",
        "clusterApprovalUndoInProgress": "Undoing clusters approval",
        "clusterRejectionFailed": "Cluster rejection failed",
        "clusterRejectionSuccess": "{clusterName} cluster has been rejected",
        "clusterRejectionUndoFailed": "Failed to undo clusters rejection",
        "clusterRejectionUndoInProgress": "Undoing clusters rejection",
        "clustersApprovalSuccess": "{n} @:storeTransfer.plurals.cluster have been approved",
        "clustersApprovalSuccessNotes": "Please make sure to complete the transfers in the next {n}\u00a0@:common.plurals.days{''}.",
        "clustersDownloadingComplete": "Clusters list loading complete",
        "clustersDownloadingFailed": "Clusters list loading complete",
        "clustersDownloadingInProgress": "Clusters list is loading",
        "clustersRecoveryFailed": "Failed to restore clusters",
        "clustersRecoveryTitle": "Cluster recovery",
        "clustersRejectionInProgress": "Rejecting @:storeTransfer.plurals.cluster",
        "clustersRejectionSuccess": "{n} @:storeTransfer.plurals.cluster have been rejected",
        "nextCalculationOnDate": "Next calculation is on {date}",
        "noMorePendingRecommendations": "No more pending recommendations",
        "noRecommendationsAvailableToday": "No recommendations are available today.",
        "noStoreTransferRequiredText": "If you would like to recalculate, please modify settings by contacting support.",
        "noStoreTransferRequiredTitle": "Current inventory allocation is optimal, no store transfers required.",
        "rejectClusterConfirmationTitle": "Are you sure you want to reject {clusterName} cluster?",
        "rejectClustersConfirmationTitle": "Are you sure you want to reject these clusters?",
        "rejectConfirmationText": "Rejecting a cluster cancels all the trips within the cluster. If you would like to recalculate the cluster, please contact support to modify settings.",
        "rejectedClustersRecoveryText": "Recover rejected cluster recommendations.",
        "storeTransferCalculationDays": "Store Transfers calculation days: {days}",
        "totalClusters": "Total of {n}\u00a0@:storeTransfer.plurals.cluster"
      },
      "navigation": {
        "products": "Products",
        "trips": "Trips"
      },
      "products": {
        "title": "Store Transfer - Products"
      },
      "rejectedClusters": "Rejected clusters",
      "skuProperties": {
        "kept": "Do not move",
        "received": "Received",
        "sent": "Send"
      },
      "summary": {
        "esi": "Estimated Sales Increase",
        "pendingClusters": "New clusters pending review",
        "pendingTrips": "New trips pending review",
        "products": "Products",
        "stores": "Stores",
        "trips": "Trips",
        "units": "Units"
      },
      "tripProperties": {
        "esi": "Estimated Sales Increase",
        "esiShort": "ESI",
        "from": "From",
        "products": "Products",
        "to": "To",
        "units": "Units"
      },
      "trips": {
        "title": "Store Transfer - Trips"
      }
    },
    "title": "Store Transfer"
  },
  "superset": {
    "bigNumber": {
      "availability": "AVG availability (%)",
      "avgDailySales": "AVG daily sales value (30 Days)",
      "avgInventoryAtSiteUnits": "AVG inventory at site (30 days)",
      "datesCount": "Days count",
      "globalHeadAvailability": "AVG availability in stores (%)",
      "globalHeadCoverage": "AVG coverage days",
      "globalHeadSales": "Sales (%)",
      "globalTailStock": "Stock cost (%)",
      "globalTailSurplus": "AVG surplus in stores (%)",
      "itr": "Inventory turnover ratio (per year)",
      "lastDayDistinctInventories": "Total inventory at site (last day)",
      "lastDaySalesValue": "Last day of sales value",
      "localHeadAvailability": "AVG availability at stores (%)",
      "localHeadCoverage": "AVG coverage days at stores",
      "localHeadSales": "AVG sales at stores (%)",
      "localTailStock": "AVG stock cost at stores (%)",
      "localTailSurplus": "AVG surplus at stores (%)",
      "lostSales": "Sum of lost sales ({currency})",
      "periodEnd": "Period end",
      "periodStart": "Period start",
      "quantityOfProducts": "Products",
      "quantityOfSkus": "SKUs",
      "surplus": "AVG daily surplus cost ({currency})",
      "totalInvOnLastDayValue": "Inventory at site value (last day)"
    },
    "chartFirstY": {
      "globalHeadTrendPercent": "%",
      "globalTailTrendPercent": "%",
      "localHeadTrendPercent": "%",
      "localTailTrendPercent": "%",
      "valueDaysOverLocationsDollarsUnits": "$/Units",
      "valueDaysOverTimeDollarsUnits": "$/Units",
      "valueOverLocationsUnits": "Units",
      "valueOverTimeDollarsUnits": "$/Units",
      "valueOverTimeUnits": "Units",
      "valuePercentOverLocationsDollarsUnits": "$/Units"
    },
    "chartLegend": {
      "avgInValue": "AVG INs value ({currency})",
      "avgOutValue": "AVG OUTs value ({currency})",
      "avgSalesValue": "AVG sales value ({currency})",
      "avgSalesValueMonthDay": "AVG Sales Value by date",
      "avgSalesValueWeekDay": "AVG sales value by day",
      "cost120Days": "120+ Days",
      "cost3059Days": "30-59 Days",
      "cost6089Days": "60-89 Days",
      "cost90119Days": "90-119 Days",
      "costNotAged": "Not aged",
      "globalHeadAvailability": "AVG availability in stores (%)",
      "globalHeadCoverage": "AVG coverage days",
      "globalHeadSales": "SUM sales (%)",
      "globalTailStock": "Stock cost (%)",
      "globalTailSurplus": "AVG surplus in stores (%)",
      "localHeadAvailability": "AVG availability in stores (%)",
      "localHeadCoverage": "AVG coverage days at stores",
      "localHeadSales": "AVG sales (%)",
      "localTailStockCost": "AVG stock cost (%)",
      "localTailSurplus": "AVG surplus in stores (%)",
      "locationSkus120Days": "120+ Days",
      "locationSkus3059Days": "30-59 Days",
      "locationSkus6089Days": "60-89 Days",
      "locationSkus90119Days": "90-119 Days",
      "locationSkusNotAged": "Not aged",
      "productsQuantity": "Products quantity",
      "sumInValue": "SUM INs value ({currency})",
      "sumOutValue": "SUM OUTs value ({currency})",
      "sumSalesValue": "SUM sales value ({currency})",
      "units120Days": "120+ Days",
      "units3059Days": "30-59 Days",
      "units6089Days": "60-89 Days",
      "units90119Days": "90-119 Days",
      "unitsNotAged": "Not aged",
      "valueDaysOverLocationsCoverage": "AVG coverage (days)",
      "valueDaysOverLocationsInv": "AVG inv' at site (units/cost)",
      "valueDaysOverLocationsLostSales": "SUM lost sales (units/price)",
      "valueDaysOverLocationsReserved": "AVG reserved inv' (units/cost)",
      "valueDaysOverLocationsSales": "SUM sales (units/price)",
      "valueDaysOverLocationsSalesRate": "AVG sales rate (units/price)",
      "valueDaysOverLocationsSurplus": "AVG surplus (units/cost)",
      "valueDaysOverLocationsTransit": "AVG inv' in transit (units/cost)",
      "valueDaysOverTimeCoverage": "AVG coverage (days)",
      "valueDaysOverTimeInv": "AVG inv' at site (units/cost)",
      "valueDaysOverTimeLostSales": "SUM lost sales (units/price)",
      "valueDaysOverTimeReserved": "AVG reserved inv' (units/cost)",
      "valueDaysOverTimeSales": "SUM sales (units/price)",
      "valueDaysOverTimeSalesRate": "AVG sales rate (units/price)",
      "valueDaysOverTimeSurplus": "AVG surplus (units/cost)",
      "valueDaysOverTimeTransit": "AVG inv' in transit (units/cost)",
      "valueOverLocationsInvCost": "AVG inv' at site (cost)",
      "valueOverLocationsInvReservedCost": "AVG reserved inv' (cost)",
      "valueOverLocationsInvReservedUnits": "AVG reserved inv' (units)",
      "valueOverLocationsInvTransitCost": "AVG inv' in transit (cost)",
      "valueOverLocationsInvTransitUnits": "AVG inv' in transit (units)",
      "valueOverLocationsInvUnits": "AVG inv' at site (units)",
      "valueOverLocationsLostSalesPrice": "SUM lost sales (price)",
      "valueOverLocationsLostSalesUnits": "SUM lost sales (units)",
      "valueOverLocationsSalesPrice": "SUM sales (price)",
      "valueOverLocationsSalesRatePrice": "AVG sales rate (price)",
      "valueOverLocationsSalesRateUnits": "AVG sales rate (units)",
      "valueOverLocationsSalesUnits": "SUM sales (units)",
      "valueOverLocationsSurplusCost": "AVG surplus (cost)",
      "valueOverLocationsSurplusUnits": "AVG surplus (units)",
      "valueOverTimeInvCost": "AVG inv' at site (cost)",
      "valueOverTimeInvReservedUnits": "AVG reserved inv' (units)",
      "valueOverTimeInvResrvedCost": "AVG reserved inv' (cost)",
      "valueOverTimeInvTransitCost": "AVG inv' in transit (cost)",
      "valueOverTimeInvTransitUnits": "AVG inv' in transit (units)",
      "valueOverTimeInvUnits": "AVG inv' at site (units)",
      "valueOverTimeLostSalesPrice": "SUM lost sales (price)",
      "valueOverTimeLostSalesUnits": "SUM lost sales (units)",
      "valueOverTimeSalesPrice": "SUM sales (price)",
      "valueOverTimeSalesRatePrice": "AVG sales rate (price)",
      "valueOverTimeSalesRateUnits": "AVG sales rate (units)",
      "valueOverTimeSalesUnits": "SUM sales (units)",
      "valueOverTimeSurplusCost": "AVG surplus (cost)",
      "valueOverTimeSurplusUnits": "AVG surplus (units)",
      "valuePercentOverLocationsAvailabilityPercent": "AVG availability (%)",
      "valuePercentOverLocationsInv": "AVG inv' at site (units/cost)",
      "valuePercentOverLocationsLostSales": "SUM lost sales (units/price)",
      "valuePercentOverLocationsReserved": "AVG reserved inv' (units/cost)",
      "valuePercentOverLocationsSales": "SUM sales (units/price)",
      "valuePercentOverLocationsSalesRate": "AVG sales rate (units/price)",
      "valuePercentOverLocationsSurplus": "AVG surplus (units/cost)",
      "valuePercentOverLocationsSurplusPercent": "AVG daily surplus (%) ",
      "valuePercentOverLocationsTransit": "AVG inv' in transit (units/cost)",
      "valuePercentOverTimeAvailabilityPercent": "AVG availability (%)",
      "valuePercentOverTimeInv": "AVG inv' at site (units/cost)",
      "valuePercentOverTimeLostSales": "SUM lost sales (units/price)",
      "valuePercentOverTimeReserved": "AVG reserved inv' (units/cost)",
      "valuePercentOverTimeSales": "SUM sales (units/price)",
      "valuePercentOverTimeSalesRate": "AVG sales rate (units/price)",
      "valuePercentOverTimeSurplus": "AVG surplus (units/cost)",
      "valuePercentOverTimeSurplusPercent": "AVG daily surplus (%)",
      "valuePercentOverTimeTransit": "AVG inv' in transit (units/cost)"
    },
    "chartSecY": {
      "globalHeadTrendDays": "Days",
      "localHeadTrendDays": "Days",
      "valueCurOverLocations": "$",
      "valueDaysOverLocationsDays": "Days",
      "valueDaysOverTimeDays": "Days",
      "valueOverTimeEconomicCur": "$",
      "valueOverTimePercent": "%",
      "valuePercentOverLocationPercent": "%"
    },
    "dashboard": {
      "catalogOverview": "Catalog overview",
      "centralMerchandise": "Central merchandise",
      "inventoryOverview": "Inventory overview",
      "locationsOverview": "Locations overview",
      "playground": "Playground",
      "retailOperations": "Retail operations",
      "storeMerchandise": "Store merchandise",
      "transactionsOverview": "Transactions overview"
    },
    "filter": {
      "avoidReplenishment": "Avoid replenishment",
      "city": "City",
      "department": "Department",
      "departmentId": "Department ID",
      "entityStatus": "Entity status",
      "locationClassification": "Location classification",
      "locationName": "Location name",
      "locationType": "Location type",
      "measurement": "Measurement",
      "product": "Product",
      "region": "Region",
      "season": "Season",
      "sku": "SKU",
      "skuName": "SKU name",
      "sourceWh": "Source WH",
      "timeFrame": "Time frame"
    },
    "tab": {
      "agedInventory": "Aged inventory",
      "brandSales": "By brand",
      "byLocations": "By locations",
      "byLocationsValue": "Quantity & Economic value",
      "byLocationsValueDays": "Quantity/Economic value & days",
      "byLocationsValuePercent": "Quantity/Economic value & percentage",
      "catalogBrand": "By brand",
      "catalogCategory": "By category",
      "catalogColor": "By color",
      "catalogDepartment": "By department",
      "catalogMarket": "By market",
      "catalogSizes": "By size",
      "catalogStyle": "By style",
      "categorySales": "By category",
      "cities": "By city",
      "colorSales": "By color",
      "costDistribution": "",
      "dailyAvgsLine": "AVG History Chart (30 Days)",
      "dailySumsLine": "SUM History Chart (30 Days)",
      "departmentSales": "By department",
      "globalHead": "Global head",
      "globalTail": "Global tail",
      "localHead": "Local head",
      "localTail": "Local tail",
      "locations": "By location",
      "marketSales": "By market",
      "monthlySalesDistribution": "Sales by Date",
      "overTime": "Over time",
      "overTimeValue": "Quantity & Economic value",
      "overTimeValueDays": "Quantity/Economic value & days",
      "overTimeValuePercent": "Quantity/Economic value & percentage",
      "priceDistribution": "",
      "regions": "By region",
      "storesAvailability": "Stores availability",
      "storesSurplus": "Stores surplus",
      "styleSales": "By style",
      "weeklySalesDistribution": "Sales by Day"
    },
    "tableCol": {
      "agedInvCategoryLevelCategory": "Category",
      "agedInvCategoryLevelOver59": "SKUs over 59 day (%)",
      "agedInvLocationLevelLocation": "Location",
      "agedInvLocationLevelOver59": "SKUs over 59 day (%)",
      "agedInvProductLevelOver59": "SKUs over 59 day (%)",
      "agedInvProductLevelProduct": "Product",
      "agedInvSkuLevelSku": "SKU",
      "availabilityCategoryLevelAvailability": "Availability (%)",
      "availabilityCategoryLevelCategory": "Category",
      "availabilityCategoryLevelLostSales": "Lost sales ({currency})",
      "availabilityLocationLevelAvailability": "Availability (%)",
      "availabilityLocationLevelLocation": "Location",
      "availabilityLocationLevelLostSales": "Lost sales ({currency})",
      "availabilityProductLevelAvailability": "Availability (%)",
      "availabilityProductLevelLostSales": "Lost sales ({currency})",
      "availabilityProductLevelProduct": "Product",
      "availabilitySkuLevelAvailability": "Availability (%)",
      "availabilitySkuLevelLostSales": "Lost sales ({currency})",
      "availabilitySkuLevelSku": "SKU",
      "avgSiteQuantityValue": "AVG inventory at site ({currency})",
      "brand": "Brand",
      "category": "Category",
      "city": "City",
      "color": "Color",
      "department": "Department",
      "firstAllocation": "First allocation",
      "globalHeadAg": "AG",
      "globalHeadCatAvailability": "AVG availability in stores (%)",
      "globalHeadCatCoverage": "AVG coverage days",
      "globalHeadCatDist": "Distribution in stores",
      "globalHeadCatSales": "SUM sales (%)",
      "globalHeadCategory": "Category",
      "globalHeadProdAvailability": "AVG availability in stores (%)",
      "globalHeadProdCoverage": "AVG coverage days",
      "globalHeadProdDist": "Distribution in stores",
      "globalHeadProdSales": "SUM sales (%)",
      "globalHeadProduct": "Product",
      "globalProdDist": "Distribution in stores",
      "globalTailAg": "AG",
      "globalTailCatStock": "SUM stock cost (%)",
      "globalTailCatSurplus": "Surplus in stores (%)",
      "globalTailCategory": "Category",
      "globalTailDist": "Distribution in stores",
      "globalTailProdStock": "SUM stock cost (%)",
      "globalTailProdSurplus": "Surplus in stores (%)",
      "globalTailProduct": "Product",
      "localHeadAg": "AG",
      "localHeadCatAvailability": "AVG availability in stores (%)",
      "localHeadCatCategory": "Category",
      "localHeadCatCoverage": "AVG coverage days",
      "localHeadCatLocation": "Location",
      "localHeadCatSales": "SUM sales (%)",
      "localHeadLocAvailability": "AVG availability at stores (%)",
      "localHeadLocCoverage": "AVG coverage days",
      "localHeadLocSales": "SUM sales (%)",
      "localHeadLocation": "Location",
      "localTailAg": "AG",
      "localTailCatCategory": "Category",
      "localTailCatLocation": "Location",
      "localTailCatStock": "SUM stock cost (%)",
      "localTailCatSurplus": "AVG surplus in stores (%)",
      "localTailLocStock": "SUM stock cost (%)",
      "localTailLocSurplus": "AVG surplus in stores (%)",
      "localTailLocation": "Location",
      "location": "Location",
      "market": "Market",
      "overstock": "Overstock",
      "quantityOfSkus": "SKU quantity",
      "quarter": "Quarter",
      "region": "Region",
      "shortagePercentage": "Shortage (%)",
      "skuSize": "SKU size",
      "statusDate": "Status date",
      "style": "Style",
      "sumLostSales": "Lost sales",
      "sumSales": "SUM sales",
      "sumSalesValue": "SUM sales value ({currency})",
      "surplusCategoryLevelCategory": "Category",
      "surplusCategoryLevelCost": "Surplus cost ({currency})",
      "surplusCategoryLevelSurplus": "AVG surplus (%)",
      "surplusLocationLevelCost": "Surplus cost ({currency})",
      "surplusLocationLevelLocation": "Location",
      "surplusLocationLevelSurplus": "AVG surplus (%)",
      "surplusProductLevelCost": "Surplus cost ({currency})",
      "surplusProductLevelProduct": "Product",
      "surplusProductLevelSurplus": "AVG surplus (%)",
      "surplusSkuLevelCost": "Surplus cost ({currency})",
      "surplusSkuLevelSku": "SKU",
      "surplusSkuLevelSurplus": "AVG surplus (%)",
      "weekday": "Day of week"
    },
    "text": {
      "agedInvCategoryLevel": "Category level",
      "agedInvCost": "Cost of inventory ({currency})",
      "agedInvLocationLevel": "Location level",
      "agedInvLocationSkus": "Location-SKUs (%)",
      "agedInvProductLevel": "Product level",
      "agedInvSkuLevel": "SKU level",
      "agedInvUnits": "Inventory units",
      "availabilityCategoryLevel": "Category level",
      "availabilityLocationLevel": "Location level",
      "availabilityProductLevel": "Product level",
      "availabilityStores": "Availability at stores (%)",
      "availabilityTransit": "Availability at transit (%)",
      "availabilityWh": "Availability at WH (%)",
      "availibilitySkuLevel": "SKU level",
      "invPerEntityStatus": "Inventory per entity status",
      "locsPerCity": "Locations per city",
      "locsPerEntityStatus": "Locations per entity status",
      "locsPerRegion": "Locations per region",
      "locsPerType": "Locations per type",
      "prodsPerBrand": "Products per brand",
      "prodsPerCategory": "Products per category",
      "prodsPerDepartment": "Products per department",
      "prodsPerEntityStatus": "Products per entity status",
      "surplusCategoryLevel": "Category level",
      "surplusCostInventoryAtStores": "Cost of inventory at stores ({currency})",
      "surplusInventoryUnitsAtStores": "Inventory units at stores",
      "surplusLocationLevel": "Location level",
      "surplusLocationSkusAtStores": "Location-SKUs at stores (%)",
      "surplusProductLevel": "Product level",
      "surplusSkuLevel": "SKU level"
    },
    "value": {
      "120Days": "120+ days",
      "3059Days": "30-59 days",
      "6089Days": "60-89 days",
      "90119Days": "90-119 days",
      "availableAtStore": "Available at store",
      "availableAtTransit": "Available at transit",
      "availableAtWarehouse": "Available at warehouse",
      "havingSurplus": "Having surplus",
      "noSurplus": "No surplus",
      "notAged": "Not aged",
      "notAvailable": "Not available",
      "weekday0": "Monday",
      "weekday1": "Tuesday",
      "weekday2": "Wednesday",
      "weekday3": "Thursday",
      "weekday4": "Friday",
      "weekday5": "Saturday",
      "weekday6": "Sunday"
    }
  },
  "ui": {
    "BatchActionsMenu": {
      "labelSelectedItems": "@:common.plurals.items selected"
    },
    "TablePagination": {
      "labelDisplayedRows": "{min}-{max} of {total} items",
      "labelItemsPerPage": "Items",
      "labelRowsPerPage": "Rows"
    },
    "datePickerShortcuts": {
      "lastDays": "Last {n}\u00a0@:common.plurals.days{''}",
      "lastMonths": "Last {n}\u00a0@:common.plurals.months{''}",
      "lastYear": "Last year"
    }
  },
  "userProfile": {
    "actions": {
      "changePassword": "Change password",
      "editProfile": "Edit profile",
      "signOut": "Sign out"
    },
    "labels": {
      "language": "Language"
    }
  },
  "validationErrors": {
    "plurals": {
      "symbol": "symbol | symbols"
    },
    "string": {
      "min": "Min length is {n} @:validationErrors.plurals.symbol"
    }
  }
}
