import { defineStore } from 'pinia';
import { z } from 'zod';
import type { ZodRawShape } from 'zod';
import { createMigratingPersistedState } from './create-migrating-persisted-state';

enum Columns {
  Name = 'name',
  ExternalId = 'external_id',
  Description = 'description',
  ProductName = 'product_name',
  ProductExternalId = 'product_external_id',
  Size = 'size',
  Styles = 'styles',
  Colors = 'colors',
  Categories = 'categories',
  DepartmentName = 'department_name',
  DepartmentExternalId = 'department_external_id',
  Brands = 'brands',
  Seasons = 'seasons',
  Markets = 'markets',
  Cost = 'cost',
  Price = 'price',
  SiteQty = 'site_qty',
  TransitQty = 'transit_qty',
  WhQty = 'wh_qty',
  ReservedQty = 'reserved_qty',
  PackConstraint = 'pack_constraint',
  TotalStores = 'total_stores',
  SaleRate = 'sale_rate',
  Coverage = 'coverage',
  AvoidReplenishment = 'avoid_replenishment',
  CreatedAt = 'created_at',
}

interface State {
  columnsVisibility: Record<string, boolean>;
  columnsOrder: string[];
  sorting: {
    sortBy: string | null;
    sortOrder: 'asc' | 'desc';
  };
  readonly __v: number;
}

function getState(): State {
  return {
    columnsVisibility: {
      [Columns.Name]: true,
      [Columns.ExternalId]: false,
      [Columns.Description]: false,
      [Columns.ProductName]: true,
      [Columns.ProductExternalId]: false,
      [Columns.Size]: true,
      [Columns.Styles]: true,
      [Columns.Colors]: true,
      [Columns.Categories]: true,
      [Columns.DepartmentName]: true,
      [Columns.DepartmentExternalId]: true,
      [Columns.Brands]: true,
      [Columns.Seasons]: true,
      [Columns.Markets]: true,
      [Columns.Cost]: true,
      [Columns.Price]: true,
      [Columns.SiteQty]: true,
      [Columns.TransitQty]: true,
      [Columns.WhQty]: true,
      [Columns.ReservedQty]: true,
      [Columns.PackConstraint]: true,
      [Columns.TotalStores]: false,
      [Columns.SaleRate]: false,
      [Columns.Coverage]: false,
      [Columns.AvoidReplenishment]: true,
      [Columns.CreatedAt]: true,
    },
    columnsOrder: [
      Columns.Name,
      Columns.ExternalId,
      Columns.Description,
      Columns.ProductName,
      Columns.ProductExternalId,
      Columns.Size,
      Columns.Styles,
      Columns.Colors,
      Columns.Categories,
      Columns.DepartmentName,
      Columns.DepartmentExternalId,
      Columns.Brands,
      Columns.Seasons,
      Columns.Markets,
      Columns.Cost,
      Columns.Price,
      Columns.SiteQty,
      Columns.TransitQty,
      Columns.WhQty,
      Columns.ReservedQty,
      Columns.PackConstraint,
      Columns.TotalStores,
      Columns.SaleRate,
      Columns.Coverage,
      Columns.AvoidReplenishment,
      Columns.CreatedAt,
    ],
    sorting: {
      sortBy: null,
      sortOrder: 'asc',
    },
    __v: 0,
  };
}

function getSchema() {
  const defaultState = getState();

  return z.object({
    columnsVisibility: z.object(
      Object.keys(defaultState.columnsVisibility).reduce<ZodRawShape>((acc, key) => {
        acc[key] = z.boolean().catch(defaultState.columnsVisibility[key]);

        return acc;
      }, {}),
    ),
    columnsOrder: z.array(z.nativeEnum(Columns)).catch(defaultState.columnsOrder as Columns[]),
    sorting: z.object({
      sortBy: z.string().nullable().catch(defaultState.sorting.sortBy),
      sortOrder: z.enum(['asc', 'desc']).catch(defaultState.sorting.sortOrder),
    }),
  });
}

export const useInventorySkusPageStore = defineStore('inventory-skus-page', {
  state: getState,
  persist: createMigratingPersistedState({
    migrations: [
      // v1 - save data from the old app setting store
      function v1(state) {
        const oldState = window.localStorage.getItem('onebeat-app:app-settings');

        if (!oldState) {
          return {};
        }

        const parsedState = JSON.parse(oldState);

        const { visibleColumns, sorting } = parsedState.pages.inventorySkus;

        return {
          ...state,
          sorting,
          columnsVisibility: {
            [Columns.Name]: visibleColumns.includes(Columns.Name),
            [Columns.ExternalId]: visibleColumns.includes(Columns.ExternalId),
            [Columns.Description]: visibleColumns.includes(Columns.Description),
            [Columns.ProductName]: visibleColumns.includes(Columns.ProductName),
            [Columns.ProductExternalId]: visibleColumns.includes(Columns.ProductExternalId),
            [Columns.Size]: visibleColumns.includes(Columns.Size),
            [Columns.Styles]: visibleColumns.includes(Columns.Styles),
            [Columns.Colors]: visibleColumns.includes(Columns.Colors),
            [Columns.Categories]: visibleColumns.includes(Columns.Categories),
            [Columns.DepartmentName]: visibleColumns.includes(Columns.DepartmentName),
            [Columns.DepartmentExternalId]: visibleColumns.includes(Columns.DepartmentExternalId),
            [Columns.Brands]: visibleColumns.includes(Columns.Brands),
            [Columns.Seasons]: visibleColumns.includes(Columns.Seasons),
            [Columns.Markets]: visibleColumns.includes(Columns.Markets),
            [Columns.Cost]: visibleColumns.includes(Columns.Cost),
            [Columns.Price]: visibleColumns.includes(Columns.Price),
            [Columns.SiteQty]: visibleColumns.includes(Columns.SiteQty),
            [Columns.TransitQty]: visibleColumns.includes(Columns.TransitQty),
            [Columns.WhQty]: visibleColumns.includes(Columns.WhQty),
            [Columns.ReservedQty]: visibleColumns.includes(Columns.ReservedQty),
            [Columns.PackConstraint]: visibleColumns.includes(Columns.PackConstraint),
            [Columns.TotalStores]: visibleColumns.includes(Columns.TotalStores),
            [Columns.SaleRate]: visibleColumns.includes(Columns.SaleRate),
            [Columns.Coverage]: visibleColumns.includes(Columns.Coverage),
            [Columns.AvoidReplenishment]: visibleColumns.includes(Columns.AvoidReplenishment),
            [Columns.CreatedAt]: visibleColumns.includes(Columns.CreatedAt),
          },
        };
      },
    ],
    schema: getSchema,
  }),
});
