import { getNativeFocused } from './get-native-focused';
import { setNativeFocused } from './set-native-focused';

export function blurNativeFocused(): void {
  const activeElement = getNativeFocused();

  if (activeElement instanceof HTMLElement) {
    setNativeFocused(activeElement, false);
  }
}
