export function getNativeFocused(): Element | null {
  if (!document.activeElement || !document.activeElement.shadowRoot) {
    return document.activeElement;
  }

  let element = document.activeElement.shadowRoot.activeElement;

  while (element && element.shadowRoot) {
    element = element.shadowRoot.activeElement;
  }

  return element;
}
