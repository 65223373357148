import { defineStore } from 'pinia';
import { useApi } from '../services/api';

interface State {
  Meilisearch: boolean;
}

export const useSystemServicesStatusStore = defineStore('system-services-status', {
  state: (): State => ({
    Meilisearch: true,
  }),
  actions: {
    async fetch(): Promise<void> {
      const api = useApi();

      const { data } = await api.getSystemServicesStatus();

      if (!data) {
        return;
      }

      this.Meilisearch = data.Meilisearch;
    },
    async clear(): Promise<void> {
      this.$reset();
    },
  },
});
