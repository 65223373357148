import { defineStore } from 'pinia';
import { useApi } from '../services/api';

export const useDataStore = defineStore('data', {
  state: () => ({
    updated_at: '',
  }),
  actions: {
    async fetch(): Promise<void> {
      const api = useApi();

      const { data } = await api.getUploads({
        page: 1,
        per_page: 1,
      });

      if (!data.length) {
        return;
      }

      this.updated_at = data[0].updated_at;
    },
    async clear(): Promise<void> {
      this.$reset();
    },
  },
});
