import { AbstractApiServiceModule } from '../abstract-api-service-module';

export type SupersetDashboard = {
  default_dashboard: boolean;
  description: string;
  locale: string | null;
  name: string;
  uuid: string;
};

export class Superset extends AbstractApiServiceModule {
  getDashboards() {
    return this.transport.get<{ data: SupersetDashboard[] }>('v1/supersets/dashboards');
  }

  getGuestToken(dashboardUuid: string) {
    return this.transport.post<{ token: string }>(`v1/supersets/guest_token/${dashboardUuid}`, {});
  }
}
