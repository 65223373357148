<script lang="ts" setup>
import { ObButton, ObSpace } from '../../components';
import { ObModal } from '../../lab/components';
import { ConfirmDialog } from './shared';
import { useConfirmDialog } from './use-confirm-dialog';

interface Props {
  dialog: ConfirmDialog;
}

const props = defineProps<Props>();

const { remove } = useConfirmDialog();

function cancel() {
  props.dialog.onCancel && props.dialog.onCancel(props.dialog);
  remove(props.dialog.id);
}

function confirm() {
  props.dialog.onConfirm && props.dialog.onConfirm(props.dialog);
  remove(props.dialog.id);
}
</script>

<template>
  <ObModal
    :active="props.dialog.active"
    width="480px"
    :title="props.dialog.title"
    :deactivate-on-overlay-click="false"
  >
    <div class="text-body-1">
      {{ props.dialog.text }}
    </div>
    <div class="mt-8">
      <ObSpace spacing="4" align-x="end">
        <ObButton variant="secondary" @click="cancel()">{{ props.dialog.cancelText }}</ObButton>
        <ObButton @click="confirm()">{{ props.dialog.confirmText }}</ObButton>
      </ObSpace>
    </div>
  </ObModal>
</template>
