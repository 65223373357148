import { AbstractApiServiceModule } from '../abstract-api-service-module';
import {
  Constraint,
  MeilisearchReplenishment,
  PaginationQuery,
  PaginatedResponse,
} from '../interfaces';

export interface ReplenishmentInventory {
  store_external_id: string;
  store_name: string;
  store_id: string;
  sku_name: string;
  sku_external_id: string;
  id: string;
  at_wh: number;
  coverage: number | null;
  last_site_qty: number;
  last_transit_qty: number;
  account_id: string;
  sale_rate: number | null;
  sku_id: string;
  replenishment: number;
  optimal_stock: number;
  inventory_needed: number;
  stock: number;
  expected_coverage: number;
  replenishment_adjustment: number;
  constraints: Constraint[];
}

export class Replenishment extends AbstractApiServiceModule {
  getInventoriesByIds(ids: string[]) {
    return this.transport.post<{ data: ReplenishmentInventory[] }>(
      '/v1/replenishments/inventories/by_ids',
      {
        ids,
      },
    );
  }

  getInventories(query?: PaginationQuery) {
    return this.transport.get<PaginatedResponse<ReplenishmentInventory>>(
      '/v1/replenishments/inventories',
      {
        query: { ...query },
      },
    );
  }

  getStores(query?: PaginationQuery) {
    return this.transport.get<PaginatedResponse<MeilisearchReplenishment>>(
      '/v1/replenishments/stores',
      {
        query: { ...query },
      },
    );
  }

  getStoresByIds(ids: string[]) {
    return this.transport.post<{ data: MeilisearchReplenishment[] }>(
      '/v1/replenishments/stores/by_ids',
      {
        ids,
      },
    );
  }
}
