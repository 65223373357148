import type { AnyZodObject } from 'zod';
import { PersistedStateOptions } from 'pinia-plugin-persistedstate';

type Migration = (state: any) => any;

interface CreateMigratingPersistedStateOptions {
  migrations: Migration[];
  schema: AnyZodObject | (() => AnyZodObject);
}

export function createMigratingPersistedState(
  options: CreateMigratingPersistedStateOptions,
): PersistedStateOptions {
  return {
    afterRestore({ store }) {
      for (let i = store.__v; i < options.migrations.length; i++) {
        if (options.migrations[i]) {
          try {
            store.$state = options.migrations[i]({ ...store.$state });
          } catch (err) {
            // TODO: log?
          }
          store.__v = i + 1;
        }
      }

      const schema = typeof options.schema === 'function' ? options.schema() : options.schema;
      const result = schema.parse(store.$state);
      store.$state = result;
      store.$persist();
    },
  };
}
