import { defineStore } from 'pinia';

type PageSettings = {
  visibleColumns: string[];
  sorting: {
    sortBy: string | null;
    sortOrder: 'asc' | 'desc';
  };
};

interface State {
  skuChart: {
    visibleMetrics: string[];
  };
  pages: {
    replenishmentStore: PageSettings;
    replenishmentStoreDetails: Pick<PageSettings, 'visibleColumns'> & { width: number };
    replenishmentStoreOverview: Omit<PageSettings, 'visibleColumns'> & { visibleMetrics: string[] };
    replenishmentProductDetails: Pick<PageSettings, 'visibleColumns'> & { width: number };
    replenishmentInventories: PageSettings;
    storeTransferTripDetails: { width: number };
    inventoryLocations: PageSettings;
    inventorySkus: PageSettings;
    inventorySkusLocations: PageSettings;
  };
}

export const useAppSettingsStore = defineStore('app-settings', {
  state: (): State => ({
    skuChart: {
      visibleMetrics: ['at_site', 'in_transit', 'optimal_stock', 'sale'],
    },
    pages: {
      replenishmentStore: {
        visibleColumns: [
          'store_name',
          'replenishment',
          'sold',
          'total_products',
          'total_skus',
          'constraints',
        ],
        sorting: {
          sortBy: null,
          sortOrder: 'asc',
        },
      },
      replenishmentStoreDetails: {
        visibleColumns: ['sku_chart', 'size', 'sold', 'replenishment', 'stock', 'constraints'],
        width: 680,
      },
      replenishmentStoreOverview: {
        visibleMetrics: ['replenishment', 'sold'],
        sorting: {
          sortBy: null,
          sortOrder: 'asc',
        },
      },
      replenishmentProductDetails: {
        visibleColumns: ['external_id', 'size', 'replenishment', 'sold', 'stock', 'constraints'],
        width: 680,
      },
      replenishmentInventories: {
        visibleColumns: [
          'location_name',
          'sku_name',
          'product_name',
          'size',
          'department_name',
          'site_qty',
          'transit_qty',
          'at_wh',
          'reserved_qty',
          'avoid_replenishment',
        ],
        sorting: {
          sortBy: null,
          sortOrder: 'asc',
        },
      },
      storeTransferTripDetails: {
        width: 680,
      },
      inventoryLocations: {
        visibleColumns: [
          'name',
          'location_type',
          'region',
          'city',
          'brands',
          'classifications',
          'at_site',
          'in_transit',
          'reserved',
          'avoid_replenishment',
        ],
        sorting: {
          sortBy: null,
          sortOrder: 'asc',
        },
      },
      inventorySkus: {
        visibleColumns: [
          'name',
          'product_name',
          'size',
          'styles',
          'colors',
          'categories',
          'department_external_id',
          'department_name',
          'brands',
          'seasons',
          'markets',
          'cost',
          'price',
          'site_qty',
          'wh_qty',
          'transit_qty',
          'reserved_qty',
          'pack_constraint',
          'avoid_replenishment',
        ],
        sorting: {
          sortBy: null,
          sortOrder: 'asc',
        },
      },
      inventorySkusLocations: {
        visibleColumns: [
          'location_name',
          'sku_name',
          'product_name',
          'size',
          'department_name',
          'site_qty',
          'transit_qty',
          'at_wh',
          'reserved_qty',
          'avoid_replenishment',
        ],
        sorting: {
          sortBy: null,
          sortOrder: 'asc',
        },
      },
    },
  }),
  persist: true,
});
