import { defineStore } from 'pinia';

type InventorySettings = {
  visibleColumns: string[];
  sorting: {
    sortBy: string | null;
    sortOrder: 'asc' | 'desc';
  };
};

interface State {
  locations: InventorySettings;
  skus: InventorySettings;
  skusLocations: InventorySettings;
}

export const useInventorySettingsStore = defineStore('inventory', {
  state: (): State => ({
    locations: {
      visibleColumns: [
        'name',
        'location_type',
        'region',
        'city',
        'brands',
        'classifications',
        'at_site',
        'in_transit',
        'reserved',
        'avoid_replenishment',
      ],
      sorting: {
        sortBy: null,
        sortOrder: 'asc',
      },
    },
    skus: {
      visibleColumns: [
        'name',
        'product_name',
        'size',
        'styles',
        'colors',
        'categories',
        'department_external_id',
        'department_name',
        'brands',
        'seasons',
        'markets',
        'cost',
        'price',
        'site_qty',
        'wh_qty',
        'transit_qty',
        'reserved_qty',
        'pack_constraint',
        'avoid_replenishment',
      ],
      sorting: {
        sortBy: null,
        sortOrder: 'asc',
      },
    },
    skusLocations: {
      visibleColumns: [
        'location_name',
        'sku_name',
        'product_name',
        'size',
        'department_name',
        'site_qty',
        'transit_qty',
        'at_wh',
        'reserved_qty',
        'avoid_replenishment',
      ],
      sorting: {
        sortBy: null,
        sortOrder: 'asc',
      },
    },
  }),
  persist: true,
});
