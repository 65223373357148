import { PaginationQuery } from './interfaces/shared';
import { AxiosTransport } from './transport';
import { KeycloakAuth } from './auth';
import type { Transport } from './transport';
import {
  ReplenishmentKpis,
  PaginatedResponse,
  Location,
  Inventory,
  InventoryDetails,
  ProductDetails,
  Product,
  ProductWithDetails,
  ReplenishmentPickingList,
  ReplenishmentOverview,
  InsufficientReplenishmentSummary,
  InsufficientReplenishmentOverviewItem,
  ProductSkuDetails,
  SpecialEventsKpis,
  InventorySkuChartOverviewItem,
  InventorySkuChartSummary,
  Upload,
  LocationKpis,
  SkuKpis,
  InventoryKpis,
  AvoidReplenishmentPayload,
  AvoidReplenishmentResponse,
  MeilisearchIndexesResponse,
  SpecialEventsDeleteResponse,
  SpecialEventsDeleteQuery,
  SpecialEventsCreateEditPayload,
  SpecialEventCategory,
  SpecialEventProduct,
  SpecialEventLocation,
  SpecialEventSku,
  ProductSkuDetailsInLocation,
  MeilisearchSpecialEvent,
  MeilisearchInventory,
  MeilisearchSku,
  MeilisearchLocation,
  MissingItem,
} from './interfaces';
import { MeiliSearchService } from './meilisearch-service';
import { StoreTransfer } from './store-transfer';
import { Superset } from './superset';
import { Replenishment } from './replenishment';

export interface ApiServiceOptions {
  transport?: Transport;
  baseUrl: string;
  meilisearchHost: string;
  keycloak: {
    url: string;
    realm: string;
    clientId: string;
    enableLogging?: boolean;
    silentCheckSsoRedirectUri: string;
  };
}

type AccountSettings = {
  name: string;
  locale: string;
  currency: string;
  custom_data_schema: {
    [scope: string]: [{ key: string; type: 'string' | 'float' | 'boolean' | 'datetime' }];
  };
};

export class ApiService {
  private readonly transport: Transport;
  readonly auth: KeycloakAuth;
  readonly meilisearch: MeiliSearchService;
  readonly storeTransfer: StoreTransfer;
  readonly superset: Superset;
  readonly replenishment: Replenishment;

  constructor(options: ApiServiceOptions) {
    this.transport =
      options?.transport ||
      new AxiosTransport({
        baseUrl: options.baseUrl,
        getAccessToken: () => {
          return this.auth.getToken();
        },
        authErrorHandler: () => {
          this.auth.logout();
        },
      });

    this.auth = new KeycloakAuth({ ...options.keycloak, transport: this.transport });
    this.meilisearch = new MeiliSearchService({
      auth: this.auth,
      transport: this.transport,
      host: options.meilisearchHost,
    });
    this.storeTransfer = new StoreTransfer({
      transport: this.transport,
    });
    this.superset = new Superset({
      transport: this.transport,
    });
    this.replenishment = new Replenishment({
      transport: this.transport,
    });
  }

  getSystemServicesStatus() {
    return this.transport.get<{ Meilisearch: boolean }>('v1/system_services');
  }

  getAccountSettings() {
    return this.transport.get<AccountSettings>('v1/account');
  }

  getMeilisearchIndexes() {
    return this.transport.get<{ data: MeilisearchIndexesResponse }>('/v1/meilisearch/indexes');
  }

  getSkuKpis() {
    return this.transport.get<SkuKpis>('/v1/skus/kpis');
  }

  getLocationKpis() {
    return this.transport.get<LocationKpis>('/v1/locations/kpis');
  }

  getInventoryKpis() {
    return this.transport.get<InventoryKpis>('/v1/inventories/kpis');
  }

  getReplenishmentKpis() {
    return this.transport.get<ReplenishmentKpis>('/v1/replenishments/kpis');
  }

  getSpecialEventsKpi() {
    return this.transport.get<SpecialEventsKpis>('/v1/special_events/kpis');
  }

  getProducts(query?: PaginationQuery & { external_id?: string }) {
    return this.transport.get<PaginatedResponse<Product>>('/v1/products', {
      query: { ...query },
    });
  }

  getProduct(id: string) {
    return this.transport.get<{ data: ProductWithDetails }>(`/v1/products/${id}`);
  }

  getProductInventoryIdsListedByLocation(productId: string) {
    return this.transport.get<Record<string, string[]>>(
      '/v1/products/inventories_list_by_location',
      {
        query: { id: productId },
      },
    );
  }

  getLocationsByIds(ids: string[]) {
    return this.transport.post<{ data: Array<MeilisearchLocation | MissingItem> }>(
      '/v1/locations/by_ids',
      {
        ids,
      },
    );
  }

  getLocation(id: string) {
    return this.transport.get<Location>(`/v1/locations/${id}`);
  }

  getLocations(query?: PaginationQuery) {
    return this.transport.get<PaginatedResponse<MeilisearchLocation>>('/v1/locations', {
      query: { ...query },
    });
  }

  getInventoriesByIds(ids: string[]) {
    return this.transport.post<{ data: Array<MeilisearchInventory | MissingItem> }>(
      '/v1/inventories/by_ids',
      {
        ids,
      },
    );
  }

  getInventories(query?: PaginationQuery) {
    return this.transport.get<PaginatedResponse<MeilisearchInventory>>('/v1/inventories', {
      query: { ...query },
    });
  }

  getInventory(id: string) {
    return this.transport.get<Inventory>(`/v1/inventories/${id}`);
  }

  getReplenishmentProductsByLocation(
    locationId: string,
    query?: PaginationQuery & { external_id?: string },
  ) {
    return this.transport.get<PaginatedResponse<Product>>('/v1/replenishments/products_by_store', {
      query: { location_id: locationId, ...query },
    });
  }

  getProductsDetails(productIds: string[]) {
    return this.transport.get<Record<string, ProductDetails>>('/v1/products/details', {
      query: { ids: productIds },
    });
  }

  getProductSkuDetails(productId: string) {
    return this.transport.get<Record<string, ProductSkuDetails>>('/v1/products/sku_details', {
      query: { id: productId },
    });
  }

  getProductSkuDetailsInLocation(productId: string, locationId: string) {
    return this.transport.get<{ data: ProductSkuDetailsInLocation[] }>(
      `v1/products/sku_details_by_store/${productId}/${locationId}`,
    );
  }

  getInventoryDetails(id: string) {
    return this.transport.get<InventoryDetails>('/v1/inventories/details', {
      query: { id },
    });
  }

  getReplenishmentPickingList() {
    return this.transport.post<ReplenishmentPickingList>(
      '/v1/replenishments/export_picking_list',
      {},
    );
  }

  getReplenishmentOverview() {
    return this.transport.get<ReplenishmentOverview>('/v1/replenishments/overview');
  }

  getInsufficientReplenishmentSummary(inventoryId: string) {
    return this.transport.get<{ data: InsufficientReplenishmentSummary }>(
      `/v1/replenishments/insufficient_replenishment/card/${inventoryId}`,
    );
  }

  getInsufficientReplenishmentOverview(inventoryId: string) {
    return this.transport.get<InsufficientReplenishmentOverviewItem[]>(
      `/v1/replenishments/insufficient_replenishment/overview/${inventoryId}`,
    );
  }

  getInventorySkuChartSummary(inventoryId: string) {
    return this.transport.get<InventorySkuChartSummary>(
      `/v1/inventories/sku_chart/card/${inventoryId}`,
    );
  }

  getInventorySkuChartOverview(
    inventoryId: string,
    query?: { start_date: string; end_date: string },
  ) {
    return this.transport.get<InventorySkuChartOverviewItem[]>(
      `/v1/inventories/sku_chart/overview/${inventoryId}`,
      {
        query,
      },
    );
  }

  getSkuByIds(ids: string[]) {
    return this.transport.post<{ data: Array<MeilisearchSku | MissingItem> }>('v1/skus/by_ids', {
      ids,
    });
  }

  getSkus(query?: PaginationQuery) {
    return this.transport.get<PaginatedResponse<MeilisearchSku>>('/v1/skus', {
      query: { ...query },
    });
  }

  getUploads(query?: PaginationQuery) {
    return this.transport.get<Upload[]>('/v1/uploads', {
      query,
    });
  }

  avoidReplenishment(payload: AvoidReplenishmentPayload) {
    const { type, ids } = payload;
    return this.transport.put<AvoidReplenishmentResponse>(
      `/v1/${type}/avoid_replenishment`,
      { ids: JSON.stringify(ids) }, // TODO: set normal array when backend will be fixed
    );
  }

  /* Special Events */

  createSpecialEvent(payload: SpecialEventsCreateEditPayload) {
    return this.transport.post<MeilisearchSpecialEvent>('v1/special_events', {
      special_event: payload,
    });
  }

  updateSpecialEvent(eventId: string, payload: SpecialEventsCreateEditPayload) {
    return this.transport.put<MeilisearchSpecialEvent>(`v1/special_events/${eventId}`, {
      special_event: payload,
    });
  }

  deleteSpecialEvents(query: SpecialEventsDeleteQuery) {
    return this.transport.delete<SpecialEventsDeleteResponse>('v1/special_events', {
      query,
    });
  }

  // Endpoints below are faked for demo

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getSpecialEventDetailedKpis(id: string) {
    // return this.transport.get<SpecialEventsDetailsKpis>('/v1/special_events/kpis', {
    //   query: { id },
    // });
    return {
      data: {
        total_categories: 4,
        total_products: 706,
        total_locations: 12,
        total_items: 5432,
        expected_cost: 130000,
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getSpecialEventLocationKpis(id: string) {
    // return this.transport.get<SpecialEventsLocationKpis>('/v1/special_events/locations_kpis', {
    //   query: { id },
    // });
    return {
      data: {
        total_units: 22,
        total_skus: 79,
        expected_cost: 1035,
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCategoriesByEventId(eventId: string): SpecialEventCategory[] {
    // return this.transport.get<SpecialEventCategory[]>('/v1/special_events/categories_by_event', {
    //   query: { id: eventId },
    // });
    const categories: SpecialEventCategory[] = [
      {
        id: 'e4f89821-0fe7-42c6-8c15-6d5780cae1b6',
        name: 'Jeans - Slim',
        factor: 11,
      },
      {
        id: 'b659455e-1ed9-4c13-aded-ba54c39f83b1',
        name: 'Dress - Mini',
        factor: 4,
      },
      {
        id: 'd1d303d3-7f4a-443f-900e-79e3d041812c',
        name: 'Dress - Midi',
        factor: 27,
      },
      {
        id: '46128d4f-5e1e-4b21-ad09-57674b036f55',
        name: 'Jeans - Relax',
        factor: 19,
      },
    ];
    return categories;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getSpecialEventProductsByLocation(locationId: string): SpecialEventProduct[] {
    // return this.transport.get<SpecialEventProduct[]>('/v1/special_events/products_by_location', {
    //   query: { id: locationId },
    // });
    const products: SpecialEventProduct[] = [
      {
        id: '5aad50bd-6378-4521-9b67-eb69970e24d7',
        name: 'High Rise Corduroy Vintage Slim Jeans with Washwell_Ruby',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/d59c2b3c-1a35-45fb-4c67-c8613af5fc00/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/d59c2b3c-1a35-45fb-4c67-c8613af5fc00/thumb',
          },
        ],
        factor: 30,
        skus: [],
      },
      {
        id: '6a2311f1-f00c-4d3f-bd33-9213bb8e64ac',
        name: 'Mid Rise Vintage Slim Jeans with Washwell_Blue',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/4d36eee0-d623-4fb3-6d1a-f5bcfd4b5900/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/4d36eee0-d623-4fb3-6d1a-f5bcfd4b5900/thumb',
          },
        ],
        factor: 25,
        skus: [],
      },
      {
        id: '7b3660c1-cfaf-40d5-8dac-f3c385010aef',
        name: 'Mid Rise Vintage Slim Jeans with Washwell_Light',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'ttps://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/4261bcde-b8f0-428f-6c28-addb70205400/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/4261bcde-b8f0-428f-6c28-addb70205400/thumb',
          },
        ],
        factor: 33,
        skus: [],
      },
    ];
    return products;
  }

  getSpecialEventProductSkus(productId: string): SpecialEventSku[] {
    const skus: SpecialEventSku[] = [
      {
        product_id: '5aad50bd-6378-4521-9b67-eb69970e24d7',
        id: 'b801a67b-139e-41d4-b8e8-53e74ac397f0',
        external_sku_id: 'SK_5013201',
        size: '27',
        units: 4,
        expected_cost: 65.4,
      },
      {
        product_id: '5aad50bd-6378-4521-9b67-eb69970e24d7',
        id: '9d203bcd-d5dc-45b9-981e-fa78b3171e65',
        external_sku_id: 'SK_5013209',
        size: '28',
        units: 1,
        expected_cost: 102,
      },
      {
        product_id: '5aad50bd-6378-4521-9b67-eb69970e24d7',
        id: 'e59dab83-bb9d-4c11-8d86-2fbd0b9701e3',
        external_sku_id: 'SK_5013217',
        size: '29',
        units: 6,
        expected_cost: 93,
      },
      {
        product_id: '5aad50bd-6378-4521-9b67-eb69970e24d7',
        id: 'ba29f7d1-3ba5-4398-902b-ed5301baecb0',
        external_sku_id: 'SK_5013225',
        size: '30',
        units: 2,
        expected_cost: 30.6,
      },
      {
        product_id: '5aad50bd-6378-4521-9b67-eb69970e24d7',
        id: 'f5fd6d5d-0a72-4fcd-a74c-627d16b5b963',
        external_sku_id: 'SK_5013233',
        size: '31',
        units: 2,
        expected_cost: 34.2,
      },
      {
        product_id: '5aad50bd-6378-4521-9b67-eb69970e24d7',
        id: '67df8037-6108-4ce1-90e7-a9925e0a1764',
        external_sku_id: 'SK_5013241',
        size: '32',
        units: 6,
        expected_cost: 85.2,
      },
      {
        product_id: '5aad50bd-6378-4521-9b67-eb69970e24d7',
        id: '2ee70ada-92fe-4341-9ee1-356066124b80',
        external_sku_id: 'SK_5013249',
        size: '34',
        units: 2,
        expected_cost: 23.4,
      },
      {
        product_id: '5aad50bd-6378-4521-9b67-eb69970e24d7',
        id: 'bfd768ca-c5f0-4451-a39c-8ff4935c5cb8',
        external_sku_id: 'SK_5013257',
        size: '35',
        units: 5,
        expected_cost: 64.2,
      },
      {
        product_id: '6a2311f1-f00c-4d3f-bd33-9213bb8e64ac',
        id: '3bdd7475-15ab-44d1-bc22-c2260b4651a2',
        external_sku_id: 'SK_3528025',
        size: '27',
        units: 5,
        expected_cost: 64.2,
      },
      {
        product_id: '6a2311f1-f00c-4d3f-bd33-9213bb8e64ac',
        id: 'e12c96f7-a3cc-422c-975f-de6230c27398',
        external_sku_id: 'SK_3528033',
        size: '28',
        units: 5,
        expected_cost: 41.4,
      },
      {
        product_id: '6a2311f1-f00c-4d3f-bd33-9213bb8e64ac',
        id: '2606e912-7fc6-4cc0-8223-4ebab3338bd3',
        external_sku_id: 'SK_3528041',
        size: '29',
        units: 3,
        expected_cost: 34.2,
      },
      {
        product_id: '6a2311f1-f00c-4d3f-bd33-9213bb8e64ac',
        id: 'ed212f7b-3eee-4121-ba77-dc38e7ddf397',
        external_sku_id: 'SK_3528049',
        size: '30',
        units: 3,
        expected_cost: 27,
      },
      {
        product_id: '6a2311f1-f00c-4d3f-bd33-9213bb8e64ac',
        id: '0e7e773d-d1f7-4139-89b4-e36313b54430',
        external_sku_id: 'SK_3528057',
        size: '31',
        units: 1,
        expected_cost: 14.4,
      },
      {
        product_id: '6a2311f1-f00c-4d3f-bd33-9213bb8e64ac',
        id: '24d8342b-0167-461d-af84-9423e95ac889',
        external_sku_id: 'SK_3528065',
        size: '32',
        units: 5,
        expected_cost: 58.8,
      },
      {
        product_id: '6a2311f1-f00c-4d3f-bd33-9213bb8e64ac',
        id: 'cf80fd55-574b-47ad-be50-d8a178824ebf',
        external_sku_id: 'SK_3528073',
        size: '34',
        units: 1,
        expected_cost: 9.6,
      },
      {
        product_id: '7b3660c1-cfaf-40d5-8dac-f3c385010aef',
        id: '8c285827-62ec-4a7c-92b4-8d003a99f0d7',
        external_sku_id: 'SK_3528097',
        size: '25',
        units: 4,
        expected_cost: 71.4,
      },
      {
        product_id: '7b3660c1-cfaf-40d5-8dac-f3c385010aef',
        id: '6ed90142-d184-49f1-9f0f-7916398fad2b',
        external_sku_id: 'SK_3528105',
        size: '26',
        units: 5,
        expected_cost: 58.2,
      },
      {
        product_id: '7b3660c1-cfaf-40d5-8dac-f3c385010aef',
        id: '96025fc3-1d35-43c6-8307-f3e8c631b423',
        external_sku_id: 'SK_3528113',
        size: '27',
        units: 4,
        expected_cost: 64.2,
      },
      {
        product_id: '7b3660c1-cfaf-40d5-8dac-f3c385010aef',
        id: 'd71e726a-72ba-4259-834d-ce837be735d5',
        external_sku_id: 'SK_3528121',
        size: '28',
        units: 5,
        expected_cost: 55.2,
      },
      {
        product_id: '7b3660c1-cfaf-40d5-8dac-f3c385010aef',
        id: 'ccfb68cf-8625-4a6d-bfc5-fffee6006ceb',
        external_sku_id: 'SK_3528129',
        size: '29',
        units: 2,
        expected_cost: 36,
      },
      {
        product_id: '7b3660c1-cfaf-40d5-8dac-f3c385010aef',
        id: '99015971-68b1-4fa2-ae9a-46fca948a9a7',
        external_sku_id: 'SK_3528137',
        size: '30',
        units: 4,
        expected_cost: 48.6,
      },
      {
        product_id: '7b3660c1-cfaf-40d5-8dac-f3c385010aef',
        id: '05c96942-a97e-4f27-a6fd-4539c60d4170',
        external_sku_id: 'SK_3528145',
        size: '31',
        units: 4,
        expected_cost: 47.4,
      },
    ];

    const data = skus.filter((item) => item.product_id === productId);

    return data;
  }

  getlocationsByCategoryId(id: string): PaginatedResponse<SpecialEventLocation> {
    const locations: SpecialEventLocation[] = [
      {
        category_id: 'e4f89821-0fe7-42c6-8c15-6d5780cae1b6',
        id: '8579a73d-183f-45f0-ab54-a6f7d52ce76f',
        name: 'Indiana Paradise',
        factor: 30,
        number_of_products: 47,
      },
      {
        category_id: 'e4f89821-0fe7-42c6-8c15-6d5780cae1b6',
        id: '04855510-9061-4345-9726-048dc68739c0',
        name: 'NY City Market',
        factor: 45,
        number_of_products: 62,
      },
      {
        category_id: 'e4f89821-0fe7-42c6-8c15-6d5780cae1b6',
        id: 'a22355ff-7716-409e-a727-72f87907c467',
        name: 'Orlando Magic Market',
        factor: 25,
        number_of_products: 34,
      },
      {
        category_id: 'e4f89821-0fe7-42c6-8c15-6d5780cae1b6',
        id: '57d3907a-3790-41d3-be63-771ac801c58b',
        name: 'Golden hill Market',
        factor: 10,
        number_of_products: 55,
      },
      {
        category_id: 'b659455e-1ed9-4c13-aded-ba54c39f83b1',
        id: '8579a73d-183f-45f0-ab54-a6f7d52ce76f',
        name: 'Oklahoma University',
        factor: 25,
        number_of_products: 47,
      },
      {
        category_id: 'b659455e-1ed9-4c13-aded-ba54c39f83b1',
        id: '04855510-9061-4345-9726-048dc68739c0',
        name: 'HNY City Marketaifa',
        factor: 33,
        number_of_products: 62,
      },
      {
        category_id: 'b659455e-1ed9-4c13-aded-ba54c39f83b1',
        id: '8cb1114b-cc81-4674-a869-a80d76029534',
        name: 'Western Emporium',
        factor: 25,
        number_of_products: 34,
      },
      {
        category_id: 'b659455e-1ed9-4c13-aded-ba54c39f83b1',
        id: 'a22355ff-7716-409e-a727-72f87907c467',
        name: 'Golden hill Market',
        factor: 15,
        number_of_products: 55,
      },
      {
        category_id: 'd1d303d3-7f4a-443f-900e-79e3d041812c',
        id: '28cb690b-9599-4c05-9c95-2ec3fa49ace5',
        name: 'Tampa Bay Sun',
        factor: 40,
        number_of_products: 23,
      },
      {
        category_id: 'd1d303d3-7f4a-443f-900e-79e3d041812c',
        id: '39d35f16-0b84-48e1-b179-02d426353c2a',
        name: 'New Orleans Jazz Market',
        factor: 25,
        number_of_products: 31,
      },
      {
        category_id: 'd1d303d3-7f4a-443f-900e-79e3d041812c',
        id: '548f7237-65e9-470b-b978-7c7c00b84581',
        name: 'Jacksonville Beach Boutique',
        factor: 35,
        number_of_products: 41,
      },
      {
        category_id: 'd1d303d3-7f4a-443f-900e-79e3d041812c',
        id: 'efa07def-5f21-4fb7-a55d-3e04417dd0c7',
        name: 'NY City Market',
        factor: 30,
        number_of_products: 78,
      },
      {
        category_id: '46128d4f-5e1e-4b21-ad09-57674b036f55',
        id: 'dc0702a2-0222-48ce-ad22-3b82ddd61b74',
        name: 'Horizon view Mall',
        factor: 25,
        number_of_products: 21,
      },
      {
        category_id: '46128d4f-5e1e-4b21-ad09-57674b036f55',
        id: 'f7ec0b3a-f200-4e18-a163-6e1e64d9f59b',
        name: 'Fort Myers Beach',
        factor: 25,
        number_of_products: 67,
      },
      {
        category_id: '46128d4f-5e1e-4b21-ad09-57674b036f55',
        id: '58fd5134-6f19-4ff4-a78f-4ef9a9240373',
        name: 'Tampa Bay Sun',
        factor: 25,
        number_of_products: 28,
      },
      {
        category_id: '46128d4f-5e1e-4b21-ad09-57674b036f55',
        id: 'ccd82197-b3fe-475d-9f92-ae211fd3ae70',
        name: 'Needle point',
        factor: 25,
        number_of_products: 21,
      },
    ];

    const data = locations.filter((item) => item.category_id === id);

    return {
      data,
      meta: {
        page: 1,
        per_page: 25,
        total: data.length,
      },
    };
  }
}
