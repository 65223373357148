import { defineStore } from 'pinia';
import { z } from 'zod';
import type { ZodRawShape } from 'zod';
import { createMigratingPersistedState } from './create-migrating-persisted-state';

enum Columns {
  LocationName = 'location_name',
  LocationExternalId = 'location_external_id',
  SkuName = 'sku_name',
  SkuExternalId = 'sku_external_id',
  SkuDescription = 'sku_description',
  LocationDescription = 'location_description',
  ProductName = 'product_name',
  ProductExternalId = 'product_external_id',
  Size = 'size',
  Styles = 'styles',
  Colors = 'colors',
  Categories = 'categories',
  DepartmentName = 'department_name',
  DepartmentExternalId = 'department_external_id',
  Brands = 'brands',
  Seasons = 'seasons',
  Markets = 'markets',
  Cost = 'cost',
  Price = 'price',
  RegionName = 'region_name',
  CityName = 'city_name',
  LocationType = 'location_type',
  Classifications = 'classifications',
  SourceLocations = 'source_locations',
  SiteQty = 'site_qty',
  TransitQty = 'transit_qty',
  AtWh = 'at_wh',
  ReservedQty = 'reserved_qty',
  SaleRate = 'sale_rate',
  OptimalStock = 'optimal_stock',
  MinTarget = 'min_target',
  Coverage = 'coverage',
  CreatedAt = 'created_at',
  AvoidReplenishment = 'avoid_replenishment',
}

interface State {
  columnsVisibility: Record<string, boolean>;
  columnsOrder: string[];
  sorting: {
    sortBy: string | null;
    sortOrder: 'asc' | 'desc';
  };
  readonly __v: number;
}

function getState(): State {
  return {
    columnsVisibility: {
      [Columns.LocationName]: true,
      [Columns.LocationExternalId]: false,
      [Columns.SkuName]: true,
      [Columns.SkuExternalId]: false,
      [Columns.SkuDescription]: false,
      [Columns.LocationDescription]: false,
      [Columns.ProductName]: true,
      [Columns.ProductExternalId]: false,
      [Columns.Size]: true,
      [Columns.Styles]: false,
      [Columns.Colors]: false,
      [Columns.Categories]: false,
      [Columns.DepartmentName]: true,
      [Columns.DepartmentExternalId]: false,
      [Columns.Brands]: false,
      [Columns.Seasons]: false,
      [Columns.Markets]: false,
      [Columns.Cost]: false,
      [Columns.Price]: false,
      [Columns.RegionName]: false,
      [Columns.CityName]: false,
      [Columns.LocationType]: false,
      [Columns.Classifications]: false,
      [Columns.SourceLocations]: false,
      [Columns.SiteQty]: true,
      [Columns.TransitQty]: true,
      [Columns.AtWh]: true,
      [Columns.ReservedQty]: true,
      [Columns.SaleRate]: false,
      [Columns.OptimalStock]: false,
      [Columns.MinTarget]: false,
      [Columns.Coverage]: false,
      [Columns.CreatedAt]: false,
      [Columns.AvoidReplenishment]: true,
    },
    columnsOrder: [
      Columns.LocationName,
      Columns.LocationExternalId,
      Columns.SkuName,
      Columns.SkuExternalId,
      Columns.SkuDescription,
      Columns.LocationDescription,
      Columns.ProductName,
      Columns.ProductExternalId,
      Columns.Size,
      Columns.Styles,
      Columns.Colors,
      Columns.Categories,
      Columns.DepartmentName,
      Columns.DepartmentExternalId,
      Columns.Brands,
      Columns.Seasons,
      Columns.Markets,
      Columns.Cost,
      Columns.Price,
      Columns.RegionName,
      Columns.CityName,
      Columns.LocationType,
      Columns.Classifications,
      Columns.SourceLocations,
      Columns.SiteQty,
      Columns.TransitQty,
      Columns.AtWh,
      Columns.ReservedQty,
      Columns.SaleRate,
      Columns.OptimalStock,
      Columns.MinTarget,
      Columns.Coverage,
      Columns.CreatedAt,
      Columns.AvoidReplenishment,
    ],
    sorting: {
      sortBy: null,
      sortOrder: 'asc',
    },
    __v: 0,
  };
}

function getSchema() {
  const defaultState = getState();

  return z.object({
    columnsVisibility: z.object(
      Object.keys(defaultState.columnsVisibility).reduce<ZodRawShape>((acc, key) => {
        acc[key] = z.boolean().catch(defaultState.columnsVisibility[key]);

        return acc;
      }, {}),
    ),
    columnsOrder: z.array(z.nativeEnum(Columns)).catch(defaultState.columnsOrder as Columns[]),
    sorting: z.object({
      sortBy: z.string().nullable().catch(defaultState.sorting.sortBy),
      sortOrder: z.enum(['asc', 'desc']).catch(defaultState.sorting.sortOrder),
    }),
  });
}

export const useInventoryInventoriesPageStore = defineStore('inventory-inventories-page', {
  state: getState,
  persist: createMigratingPersistedState({
    migrations: [
      // v1 - save data from the old app setting store
      function v1(state) {
        const oldState = window.localStorage.getItem('onebeat-app:app-settings');

        if (!oldState) {
          return {};
        }

        const parsedState = JSON.parse(oldState);

        const { visibleColumns, sorting } = parsedState.pages.inventorySkusLocations;

        return {
          ...state,
          sorting,
          columnsVisibility: {
            [Columns.LocationName]: visibleColumns.includes(Columns.LocationName),
            [Columns.LocationExternalId]: visibleColumns.includes(Columns.LocationExternalId),
            [Columns.SkuName]: visibleColumns.includes(Columns.SkuName),
            [Columns.SkuExternalId]: visibleColumns.includes(Columns.SkuExternalId),
            [Columns.SkuDescription]: visibleColumns.includes(Columns.SkuDescription),
            [Columns.LocationDescription]: visibleColumns.includes(Columns.LocationDescription),
            [Columns.ProductName]: visibleColumns.includes(Columns.ProductName),
            [Columns.ProductExternalId]: visibleColumns.includes(Columns.ProductExternalId),
            [Columns.Size]: visibleColumns.includes(Columns.Size),
            [Columns.Styles]: visibleColumns.includes(Columns.Styles),
            [Columns.Colors]: visibleColumns.includes(Columns.Colors),
            [Columns.Categories]: visibleColumns.includes(Columns.Categories),
            [Columns.DepartmentName]: visibleColumns.includes(Columns.DepartmentName),
            [Columns.DepartmentExternalId]: visibleColumns.includes(Columns.DepartmentExternalId),
            [Columns.Brands]: visibleColumns.includes(Columns.Brands),
            [Columns.Seasons]: visibleColumns.includes(Columns.Seasons),
            [Columns.Markets]: visibleColumns.includes(Columns.Markets),
            [Columns.Cost]: visibleColumns.includes(Columns.Cost),
            [Columns.Price]: visibleColumns.includes(Columns.Price),
            [Columns.RegionName]: visibleColumns.includes(Columns.RegionName),
            [Columns.CityName]: visibleColumns.includes(Columns.CityName),
            [Columns.LocationType]: visibleColumns.includes(Columns.LocationType),
            [Columns.Classifications]: visibleColumns.includes(Columns.Classifications),
            [Columns.SourceLocations]: visibleColumns.includes(Columns.SourceLocations),
            [Columns.SiteQty]: visibleColumns.includes(Columns.SiteQty),
            [Columns.TransitQty]: visibleColumns.includes(Columns.TransitQty),
            [Columns.AtWh]: visibleColumns.includes(Columns.AtWh),
            [Columns.ReservedQty]: visibleColumns.includes(Columns.ReservedQty),
            [Columns.SaleRate]: visibleColumns.includes(Columns.SaleRate),
            [Columns.OptimalStock]: visibleColumns.includes(Columns.OptimalStock),
            [Columns.MinTarget]: visibleColumns.includes(Columns.MinTarget),
            [Columns.Coverage]: visibleColumns.includes(Columns.Coverage),
            [Columns.CreatedAt]: visibleColumns.includes(Columns.CreatedAt),
            [Columns.AvoidReplenishment]: visibleColumns.includes(Columns.AvoidReplenishment),
          },
        };
      },
    ],
    schema: getSchema,
  }),
});
