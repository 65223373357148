<template>
  <button type="button" :class="$style.button">
    <slot />
  </button>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/typography';
@use '../../styles/shared';

.button {
  @include shared.reset-button();
  border-radius: shared.$border-radius-s;
  background: transparent;
  color: currentColor;
  border: 1px solid currentColor;
  font-family: typography.$font-family-primary;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 12px;
  display: inline-flex;
  align-items: center;
  height: 32px;
}
</style>
