import { MeiliSearch } from 'meilisearch';
import jwtDecode from 'jwt-decode';
import type { Transport } from './transport';
import type { Auth } from './auth';
import type { MeilisearchTokenResponse } from './interfaces';

interface MeiliSearchServiceOptions {
  host: string;
  transport: Transport;
  auth: Auth;
}

export class MeiliSearchService {
  protected readonly host: string;
  protected readonly transport: Transport;
  protected readonly auth: Auth;

  protected tokenExpiresAt = 0;
  protected minTokenValidity = 1000 * 30;
  protected clientPromise?: Promise<MeiliSearch>;

  protected client?: MeiliSearch;

  constructor(options: MeiliSearchServiceOptions) {
    this.host = options.host;
    this.transport = options.transport;
    this.auth = options.auth;

    this.auth.onStateChanged(({ authenticated }) => {
      if (!authenticated) {
        this.client = undefined;
        this.tokenExpiresAt = 0;
      }
    });
  }

  async getClient(): Promise<MeiliSearch> {
    if (this.clientPromise) {
      return this.clientPromise;
    }

    if (this.client && this.tokenExpiresAt - Date.now() > this.minTokenValidity) {
      return this.client;
    }

    this.clientPromise = this.createClient();

    this.clientPromise.then(() => {
      this.clientPromise = undefined;
    });

    return this.clientPromise;
  }

  protected async checkAuth() {
    await this.auth.isReady();

    if (!this.auth.isAuthenticated) {
      throw new Error('Cannot create MeiliSearch client due to user is not authenticated');
    }
  }

  protected async getToken(): Promise<string> {
    const { data } = await this.transport.post<MeilisearchTokenResponse>('/v1/meilisearch', {});

    return data.token;
  }

  protected async createClient(): Promise<MeiliSearch> {
    await this.checkAuth();

    const token = await this.getToken();

    this.client = new MeiliSearch({
      host: this.host,
      apiKey: token,
    });

    const { exp } = jwtDecode<{ exp: number }>(token);
    this.tokenExpiresAt = exp * 1000;

    return this.client;
  }
}
