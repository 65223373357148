import type { Guard } from './interfaces';

export const authGuard: Guard = (from, to, { api }) => {
  return api.auth.isAuthenticated()
    ? true
    : {
        name: 'auth',
        query: {
          returnUrl: to.fullPath,
        },
      };
};
