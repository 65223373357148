import type { ErrorResponse } from '../interfaces';
import type { TransportError, TransportResponseHeaders } from './interfaces';

export abstract class AbstractTransportError extends Error implements TransportError {
  abstract headers: TransportResponseHeaders;

  abstract status: number;

  abstract statusText: string;

  abstract data?: ErrorResponse;

  isTransportError = true;
}
