import type { ErrorResponse } from '../interfaces';
import type { TransportResponseHeaders } from './interfaces';
import { AbstractTransportError } from './abstract-transport-error';

interface AxiosTransportErrorOptions {
  message?: string;
  headers: TransportResponseHeaders;
  status: number;
  statusText: string;
  data?: ErrorResponse;
}

export class AxiosTransportError extends AbstractTransportError {
  headers: TransportResponseHeaders;
  status: number;
  statusText: string;
  data?: ErrorResponse;

  constructor(options: AxiosTransportErrorOptions) {
    // TODO: get message from options.data[default_path]
    super(options.message || options.statusText);

    this.headers = options.headers;
    this.status = options.status;
    this.statusText = options.statusText;
    this.data = options.data;
  }
}
