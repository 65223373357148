import { defineStore } from 'pinia';

interface State {
  visibleMetrics: string[];
}

export const useSkuChartSettingsStore = defineStore('replenishment', {
  state: (): State => ({
    visibleMetrics: ['at_site', 'in_transit', 'optimal_stock', 'sale'],
  }),
  persist: true,
});
