import { defineStore } from 'pinia';

type ReplenishmentSettings = {
  visibleColumns: string[];
  sorting: {
    sortBy: string | null;
    sortOrder: 'asc' | 'desc';
  };
};

interface State {
  store: ReplenishmentSettings;
  storeDetails: Pick<ReplenishmentSettings, 'visibleColumns'> & { width: number };
  storeOverview: Omit<ReplenishmentSettings, 'visibleColumns'> & {
    visibleMetrics: string[];
  };
  productDetails: Pick<ReplenishmentSettings, 'visibleColumns'> & { width: number };
}

export const useReplenishmentSettingsStore = defineStore('replenishment', {
  state: (): State => ({
    store: {
      visibleColumns: [
        'store_name',
        'replenishment',
        'sold',
        'total_products',
        'total_skus',
        'constraints',
      ],
      sorting: {
        sortBy: null,
        sortOrder: 'asc',
      },
    },
    storeDetails: {
      visibleColumns: ['sku_chart', 'size', 'sold', 'replenishment', 'stock', 'constraints'],
      width: 680,
    },
    storeOverview: {
      visibleMetrics: ['replenishment', 'sold'],
      sorting: {
        sortBy: null,
        sortOrder: 'asc',
      },
    },
    productDetails: {
      visibleColumns: ['external_id', 'size', 'replenishment', 'sold', 'stock', 'constraints'],
      width: 680,
    },
  }),
  persist: true,
});
