// Map between weekdays comes from the backend and week day number

import type { WeekDay, WeekDayName } from './types';

const weekDaysMapping: Record<WeekDayName, WeekDay> = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export function getWeekDayNumber(name: WeekDayName): WeekDay {
  return weekDaysMapping[name];
}
