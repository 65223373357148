import { ref, computed, provide, inject } from 'vue';
import type { MaybeRef, ComputedRef, InjectionKey } from 'vue';
import { useStack } from '../use-stack';
import { POPOVER_Z_INDEX_BASE } from '../../shared/constants';
import { ZIndexStacks } from '../../shared/enums';

interface UseZIndexStackReturn {
  zIndex: ComputedRef<number | undefined>;
}

interface UseZIndexStackOptions {
  active: MaybeRef<boolean>;
  name: string;
  base: number;
  size?: number;
}

const Z_INDEX_STACK: InjectionKey<string> = Symbol(__DEV__ ? 'z-index stack' : '');

const stacks = ref<Record<string, { base: number }>>({});

export function useZIndexStack(options: UseZIndexStackOptions): UseZIndexStackReturn {
  stacks.value[options.name] = {
    base: options.base,
  };

  const { index, exists, first, stack } = useStack<{ size: number }>(options.name, {
    active: options.active,
    payload: {
      size: options?.size ?? 1,
    },
  });

  const zIndex = computed<number | undefined>(() => {
    if (!exists.value) {
      return undefined;
    }

    const { base } = stacks.value[options.name];

    if (first.value) {
      return base;
    }

    return (
      base + stack.value.slice(0, index.value).reduce((acc, { payload }) => acc + payload.size, 0)
    );
  });

  provide(Z_INDEX_STACK, options.name);

  return {
    zIndex,
  };
}

interface UseParentZIndexStackOptions {
  active: MaybeRef<boolean>;
  fallbackName: string;
  fallbackBase: number;
  size?: number;
}

export function useParentZIndexStack(options: UseParentZIndexStackOptions) {
  const parentStackName = inject(Z_INDEX_STACK, undefined);

  return useZIndexStack({
    name: parentStackName ?? options.fallbackName,
    base: parentStackName ? stacks.value[parentStackName].base : options.fallbackBase,
    active: options.active,
    size: options.size,
  });
}

export function usePopoverZIndexStack(
  options: Pick<UseParentZIndexStackOptions, 'active' | 'size'>,
) {
  return useParentZIndexStack({
    active: options.active,
    size: options.size,
    fallbackName: ZIndexStacks.Popovers,
    fallbackBase: POPOVER_Z_INDEX_BASE,
  });
}
