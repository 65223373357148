import { defineStore } from 'pinia';
import { isSameDay } from 'date-fns';
import { StoreTransferRecommendationsCluster, StoreTransferWeekDay, useApi } from '../services/api';
import { getWeekDayNumber } from '../shared/get-week-day-number';

interface State {
  lastCalculationDate: string;
  calculationActive: boolean;
  weekDays: StoreTransferWeekDay[];
  clusters: StoreTransferRecommendationsCluster[];
  totalClusters: number;
  readyClusters: number;
}

export const useStoreTransferRecommendationsStore = defineStore('store-transfer-recommendations', {
  state: (): State => ({
    clusters: [],
    calculationActive: false,
    lastCalculationDate: '',
    weekDays: [],
    totalClusters: 0,
    readyClusters: 0,
  }),
  actions: {
    async fetch(): Promise<void> {
      const api = useApi();

      const { data } = await api.storeTransfer.getStoreTransferRecommendations();

      if (!data) {
        return;
      }

      this.clusters = data.data.clusters;
      this.calculationActive = data.data.is_active;
      this.lastCalculationDate = data.data.last_st_date;
      this.weekDays = data.data.week_days;
      this.totalClusters = data.data.total_clusters;
      this.readyClusters = data.data.ready_clusters;
    },
    async clear(): Promise<void> {
      this.$reset();
    },
  },
  getters: {
    calculationInProgress: ({ totalClusters, readyClusters }) => readyClusters < totalClusters,
    calculationWeekDays: ({ weekDays }) => weekDays.map(getWeekDayNumber).sort(),
    pendingClusters: ({ clusters }) => clusters.filter(({ trips }) => trips[0]?.state === 'none'),
    rejectedClusters: ({ clusters }) =>
      clusters.filter(({ trips }) => trips[0]?.state === 'rejected'),
    approvedClusters: ({ clusters }) =>
      clusters.filter(({ trips }) => trips[0]?.state === 'approved'),
    allClustersAreApproved: ({ clusters }) =>
      clusters.length > 0 &&
      !clusters.some(({ trips }) => trips.length > 0 && trips[0]?.state !== 'approved'),
    recommendationsAvailable: ({ lastCalculationDate: lastCalculationDate }) => {
      if (!lastCalculationDate) {
        return false;
      }

      return isSameDay(new Date(lastCalculationDate), new Date());
    },
  },
});
