import { defineStore } from 'pinia';

interface State {
  visibleMetrics: { [key: string]: string[] };
}

export const useSupersetStore = defineStore('superset', {
  state: (): State => ({
    visibleMetrics: {},
  }),
  persist: true,
});
