import { defineStore } from 'pinia';
import { z } from 'zod';
import type { ZodRawShape } from 'zod';
import { createMigratingPersistedState } from './create-migrating-persisted-state';

enum Columns {
  StoreName = 'store_name',
  StoreExternalId = 'store_external_id',
  City = 'city',
  Sold = 'sold',
  Coverage = 'coverage',
  Replenishment = 'replenishment',
  ExpectedCoverage = 'expected_coverage',
  AvgDailySales = 'avg_daily_sales',
  SourceLocations = 'source_locations',
  Region = 'region',
  TotalProducts = 'total_products',
  TotalSkus = 'total_skus',
  ReplenishmentTime = 'replenishment_time',
  Classifications = 'classifications',
  Brands = 'brands',
  SoldDepleted = 'sold_depleted',
  CoverageDepleted = 'coverage_depleted',
  AvgDailySalesDepleted = 'avg_daily_sales_depleted',
  Constraints = 'constraints',
}

interface State {
  columnsVisibility: Record<string, boolean>;
  columnsOrder: string[];
  sorting: {
    sortBy: string | null;
    sortOrder: 'asc' | 'desc';
  };
  readonly __v: number;
}

function getState(): State {
  return {
    columnsVisibility: {
      [Columns.StoreName]: true,
      [Columns.StoreExternalId]: false,
      [Columns.City]: false,
      [Columns.Sold]: true,
      [Columns.Coverage]: false,
      [Columns.Replenishment]: true,
      [Columns.ExpectedCoverage]: false,
      [Columns.AvgDailySales]: false,
      [Columns.SourceLocations]: false,
      [Columns.Region]: false,
      [Columns.TotalProducts]: true,
      [Columns.TotalSkus]: true,
      [Columns.ReplenishmentTime]: false,
      [Columns.Classifications]: false,
      [Columns.Brands]: false,
      [Columns.SoldDepleted]: false,
      [Columns.CoverageDepleted]: false,
      [Columns.AvgDailySalesDepleted]: false,
      [Columns.Constraints]: true,
    },
    columnsOrder: [
      Columns.StoreName,
      Columns.StoreExternalId,
      Columns.City,
      Columns.Sold,
      Columns.Coverage,
      Columns.Replenishment,
      Columns.ExpectedCoverage,
      Columns.AvgDailySales,
      Columns.SourceLocations,
      Columns.Region,
      Columns.TotalProducts,
      Columns.TotalSkus,
      Columns.ReplenishmentTime,
      Columns.Classifications,
      Columns.Brands,
      Columns.SoldDepleted,
      Columns.CoverageDepleted,
      Columns.AvgDailySalesDepleted,
      Columns.Constraints,
    ],
    sorting: {
      sortBy: null,
      sortOrder: 'asc',
    },
    __v: 0,
  };
}

function getSchema() {
  const defaultState = getState();

  return z.object({
    columnsVisibility: z.object(
      Object.keys(defaultState.columnsVisibility).reduce<ZodRawShape>((acc, key) => {
        acc[key] = z.boolean().catch(defaultState.columnsVisibility[key]);

        return acc;
      }, {}),
    ),
    columnsOrder: z.array(z.nativeEnum(Columns)).catch(defaultState.columnsOrder as Columns[]),
    sorting: z.object({
      sortBy: z.string().nullable().catch(defaultState.sorting.sortBy),
      sortOrder: z.enum(['asc', 'desc']).catch(defaultState.sorting.sortOrder),
    }),
  });
}

export const useReplenishmentStoresPageStore = defineStore('replenishment-stores-page', {
  state: getState,
  persist: createMigratingPersistedState({
    migrations: [
      // v1 - save data from the old app setting store
      function v1(state) {
        const oldState = window.localStorage.getItem('onebeat-app:app-settings');

        if (!oldState) {
          return {};
        }

        const parsedState = JSON.parse(oldState);

        const { visibleColumns, sorting } = parsedState.pages.replenishmentStore;

        return {
          ...state,
          sorting,
          columnsVisibility: {
            [Columns.StoreName]: visibleColumns.includes(Columns.StoreName),
            [Columns.StoreExternalId]: visibleColumns.includes(Columns.StoreExternalId),
            [Columns.City]: visibleColumns.includes(Columns.City),
            [Columns.Sold]: visibleColumns.includes(Columns.Sold),
            [Columns.Coverage]: visibleColumns.includes(Columns.Coverage),
            [Columns.Replenishment]: visibleColumns.includes(Columns.Replenishment),
            [Columns.ExpectedCoverage]: visibleColumns.includes(Columns.ExpectedCoverage),
            [Columns.AvgDailySales]: visibleColumns.includes(Columns.AvgDailySales),
            [Columns.SourceLocations]: visibleColumns.includes(Columns.SourceLocations),
            [Columns.Region]: visibleColumns.includes(Columns.Region),
            [Columns.TotalProducts]: visibleColumns.includes(Columns.TotalProducts),
            [Columns.TotalSkus]: visibleColumns.includes(Columns.TotalSkus),
            [Columns.ReplenishmentTime]: visibleColumns.includes(Columns.ReplenishmentTime),
            [Columns.Classifications]: visibleColumns.includes(Columns.Classifications),
            [Columns.Brands]: visibleColumns.includes(Columns.Brands),
            [Columns.SoldDepleted]: visibleColumns.includes(Columns.SoldDepleted),
            [Columns.CoverageDepleted]: visibleColumns.includes(Columns.CoverageDepleted),
            [Columns.AvgDailySalesDepleted]: visibleColumns.includes(Columns.AvgDailySalesDepleted),
            [Columns.Constraints]: visibleColumns.includes(Columns.Constraints),
          },
        };
      },
    ],
    schema: getSchema,
  }),
});
