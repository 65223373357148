import { computed } from 'vue';
import type { ComputedRef } from 'vue';

interface UseButtonOptions {
  element?: 'a' | 'button' | 'input';
  disabled?: boolean;
  href?: string;
  rel?: string;
  target?: string;
  type?: 'button' | 'submit' | 'reset';
  tabIndex?: string;
}

interface UseButtonReturn {
  buttonProps: ComputedRef<Record<string, unknown>>; // TODO: better type
}

export function useButton(options: UseButtonOptions = {}): UseButtonReturn {
  const buttonProps = computed(() => {
    const { element = 'button', disabled, href, rel, target, type = 'button', tabIndex } = options;

    if (element === 'button') {
      return {
        disabled,
        tabindex: disabled ? undefined : tabIndex,
        type,
      };
    }

    let role;

    if (element === 'a') {
      if (disabled) {
        role = 'link';
      }
    } else {
      role = 'button';
    }

    return {
      'aria-disabled': !disabled || element === 'input' ? undefined : disabled,
      disabled: element === 'input' ? disabled : undefined,
      href: element === 'a' && !disabled ? href : undefined,
      rel: element === 'a' ? rel : undefined,
      role,
      tabindex: disabled ? undefined : tabIndex,
      target: element === 'a' ? target : undefined,
      type: element === 'input' ? type : undefined,
    };
  });

  return {
    buttonProps,
  };
}
