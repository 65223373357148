import { createApp as createVueApp } from 'vue';
import type { App as VueApp } from 'vue';
import * as Sentry from '@sentry/vue';
import { createHead } from '@vueuse/head';
import { createRouter } from '../router';
import { createStore } from '../store';
import { createApi } from '../services/api';
import { createI18n } from '../services/i18n';
import { createHeap } from '../services/heap';
import { createUnleash } from '../services/unleash';
import { createToaster, createConfirmDialog } from '../ui-kit';
import { config } from './config';
import App from './app.vue';

const router = createRouter({
  baseUrl: config.baseUrl,
});

export function createApp(): VueApp {
  const app = createVueApp(App)
    .use(
      createApi({
        baseUrl: config.apiUrl,
        keycloak: config.keycloak,
        meilisearchHost: config.meilisearch.host,
      }),
    )
    .use(createStore())
    .use(router)
    .use(createI18n())
    .use(
      createUnleash({
        url: config.unleash.url,
        clientKey: config.unleash.clientKey,
        appName: config.unleash.appName,
        refreshInterval: config.unleash.refreshInterval,
        bootstrap: __UNLEASH_BOOTSTRAP__,
      }),
    )
    .use(createToaster())
    .use(createConfirmDialog())
    .use(
      createHead({
        titleTemplate: (value) => `${value ? value + ' | ' : ''}Onebeat App`,
      }),
    );

  if (config.heap.enabled) {
    app.use(createHeap(config.heap.appId));
  }

  if (config.sentry.enabled) {
    Sentry.init({
      app,
      environment: config.sentry.environment,
      dsn: config.sentry.dsn,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['localhost', /^\//],
        }),
      ],
      tracesSampleRate: config.sentry.environment === 'production' ? 0.25 : 1,
      tracingOptions: {
        trackComponents: true,
      },
      attachProps: true,
      attachStacktrace: true,
      logErrors: config.sentry.environment !== 'production',
    });
  }

  app.config.errorHandler = (error) => {
    if (config.sentry.enabled) {
      Sentry.captureException(error);
    }

    console.error(error);
  };

  return app;
}
