import { AbstractApiServiceModule } from '../abstract-api-service-module';
import { ProductDepartment, ProductCategory, ProductImage } from '../interfaces/shared';

export type StoreTransferWeekDay =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';

export type StoreTransferRecommendationsTripSate = 'none' | 'rejected' | 'approved';

export type StoreTransferRecommendationsTrip = {
  esi: number;
  id: string;
  quantity: number;
  source_location_external_id: string;
  source_location_name: string;
  state: StoreTransferRecommendationsTripSate;
  target_location_external_id: 'aeon_0_store';
  target_location_name: string;
  total_products: number;
};

export type StoreTransferRecommendationsCluster = {
  id: string;
  cluster_name: string;
  total_esi: number;
  total_trips: number;
  total_stores: number;
  total_products: number;
  total_units: number;
  trips: StoreTransferRecommendationsTrip[];
};

export type StoreTransferRecommendationsData = {
  last_st_date: string;
  is_active: boolean;
  week_days: StoreTransferWeekDay[];
  clusters: StoreTransferRecommendationsCluster[];
  total_clusters: number;
  ready_clusters: number;
};

export type StoreTransferTripDetailsProduct = {
  product_id: string;
  product_name: string;
  product_external_id: string;
  total_units: number;
  total_esi: number;
  images: ProductImage[];
};

export type StoreTransferTripDetails = {
  id: string;
  source_location_name: string;
  target_location_name: string;
  total_products: number;
  total_units: number;
  total_esi: number;
  products: StoreTransferTripDetailsProduct[];
};

export type StoreTransferSettings = {
  id: string;
  minimum_service_level: number;
  depleted_wh_threshold: number;
  first_allocation_date_threshold: number;
  planning_horizon: number;
  time_to_execution: number;
  minimum_esi_per_product_trip: number;
  minimum_esi_per_trip: number;
  left_over: boolean;
  pull_back_to_wh: boolean;
  service_level_leftover_threshold: number;
  leftover_push_over: number;
  account_id: string;
  is_active: boolean;
  days: StoreTransferWeekDay[];
  clusters_qty: number;
};

export type StoreTransferRecommendationsProduct = {
  id: string;
  external_id: string;
  name: string;
  category_name: string;
  total_units: number;
  total_esi: number;
  location_external_ids: string[];
  images: ProductImage[];
};

export interface StoreTransferRecommendationsProductInformation {
  id: string;
  external_id: string;
  name: string;
  total_esi: number;
  locations_count: number;
  total_units: number;
  categories: ProductCategory[];
  departments: ProductDepartment[];
  colors: string[];
  styles: string[];
  markets: string[];
  brands: string[];
  seasons: string[];
  images: ProductImage[];
}

export interface StoreTransferRecommendationsProductClusters {
  id: string;
  name: string;
  total_esi: number;
  trips_count: number;
  total_units: number;
  locations_count: number;
}

export interface StoreTransferProductClustersLocationSize {
  name: string;
  kept: number;
  received: number;
  sent: number;
}

export interface StoreTransferProductClustersLocation {
  id: string;
  name: string;
  sale_rate: number;
  kept: number;
  received: number;
  sent: number;
  sizes: undefined;
}

export interface StoreTransferProductClustersLocationWithSizes {
  id: string;
  name: string;
  sale_rate: number;
  sizes: StoreTransferProductClustersLocationSize[];
}

export interface StoreTransferStateUpdateResponse {
  updated: string[];
  state: StoreTransferRecommendationsTripSate;
}

export class StoreTransfer extends AbstractApiServiceModule {
  getSettings() {
    return this.transport.get<{ data: StoreTransferRecommendationsData }>(
      '/v1/store_transfer_settings',
    );
  }

  getStoreTransferRecommendations() {
    return this.transport.get<{ data: StoreTransferRecommendationsData }>('/v1/store_transfers');
  }

  getProducts() {
    return this.transport.get<{ data: StoreTransferRecommendationsProduct[] }>(
      'v1/store_transfers/products',
    );
  }

  getProduct(productId: string) {
    return this.transport.get<{ data: StoreTransferRecommendationsProductInformation }>(
      `v1/store_transfers/products/${productId}`,
    );
  }

  getProductClusters(productId: string) {
    return this.transport.get<{ data: StoreTransferRecommendationsProductClusters[] }>(
      `v1/store_transfers/products/${productId}/clusters`,
    );
  }

  getProductClusterLocations(productId: string, clusterId: string) {
    return this.transport.get<{
      data:
        | StoreTransferProductClustersLocation[]
        | StoreTransferProductClustersLocationWithSizes[];
    }>(`v1/store_transfers/products/${productId}/clusters/${clusterId}/locations`);
  }

  setClustersState(state: StoreTransferRecommendationsTripSate, clustersIds?: string[]) {
    return this.transport.patch<{
      data: StoreTransferStateUpdateResponse;
    }>('/v1/store_transfers/update_trips', {
      state,
      ids: clustersIds,
    });
  }

  downloadPickingList(clusterIds?: string[]) {
    return this.transport.get<{ headers: string[]; data: string[][] }>(
      '/v1/store_transfers/download_trips',
      {
        query: {
          cluster_ids: clusterIds,
        },
      },
    );
  }

  downloadRecommendedClusters() {
    return this.transport.get<{ headers: string[]; data: string[][] }>('v1/clusters', {});
  }

  getTripDetails(id: string) {
    return this.transport.get<{ data: StoreTransferTripDetails }>(`/v1/trips/${id}`);
  }
}
