import { defineStore } from 'pinia';
import { useApi } from '../services/api';

interface State {
  name: string;
  locale: string;
  currency: string;
  customDataSchema: {
    [scope: string]: [{ key: string; type: 'string' | 'float' | 'boolean' | 'datetime' }];
  };
}

export const useAccountStore = defineStore('account', {
  state: (): State => ({
    name: '',
    locale: '',
    currency: '',
    customDataSchema: {},
  }),
  actions: {
    async fetch(): Promise<void> {
      const api = useApi();

      const { data } = await api.getAccountSettings();

      if (!data) {
        return;
      }

      this.name = data.name;
      this.locale = data.locale;
      this.currency = data.currency;
      this.customDataSchema = data.custom_data_schema;
    },
    async clear(): Promise<void> {
      this.$reset();
    },
  },
});
