import type { Transport } from './transport';

interface AbstractApiServiceModuleOptions {
  transport: Transport;
}

export abstract class AbstractApiServiceModule {
  protected readonly transport: Transport;

  constructor(options: AbstractApiServiceModuleOptions) {
    this.transport = options.transport;
  }
}
