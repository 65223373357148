<script lang="ts" setup>
import type { AnchorTargetAttribute, SizeM, SizeL } from '../../shared/types';
import { useButton } from '../../composables';
import { hasSlotContent } from '../../utils';

interface Props {
  element?: 'a' | 'button';
  disabled?: boolean;
  active?: boolean;
  tabIndex?: string;
  size?: SizeM | SizeL;
  variant?: 'primary' | 'secondary' | 'tertiary';
  width?: 'auto' | 'stretch';
  // only for button element type
  type?: 'button' | 'submit' | 'reset';
  // only for a element type
  href?: string;
  target?: AnchorTargetAttribute;
}

const props = withDefaults(defineProps<Props>(), {
  element: 'button',
  disabled: false,
  active: false,
  tabIndex: undefined,
  size: 'm',
  variant: 'primary',
  width: 'auto',
  type: 'button',
  href: undefined,
  rel: undefined,
  target: undefined,
});

const { buttonProps } = useButton(props);
</script>

<template>
  <component
    :is="props.element"
    :class="[
      $style.root,
      {
        [$style.sizeL]: props.size === 'l',
        [$style.variantSecondary]: props.variant === 'secondary',
        [$style.variantTertiary]: props.variant === 'tertiary',
        [$style.active]: props.active,
        [$style.widthStretch]: props.width === 'stretch',
      },
    ]"
    v-bind="buttonProps"
  >
    <span v-if="hasSlotContent($slots.icon)" :class="$style.icon">
      <slot name="icon" />
    </span>
    <span v-if="hasSlotContent($slots.default)" :class="$style.text"><slot /></span>
  </component>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/iconography';
@use '../../styles/shared';
@use '../../styles/typography';

.root {
  @include shared.reset-button();
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: shared.$border-radius-s;
  font-family: typography.$font-family-primary;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  padding: 4px 8px;
  height: 32px;
  background: #7a68e3; // TODO: use token
  color: #fff; // TODO: use token

  &::before {
    @include shared.coverer();
    content: '';
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: inherit;
  }

  &:focus-visible {
    background: #5b48cf; // TODO: use token

    &::before {
      border-color: #021148; // TODO: use token
    }
  }

  &:hover {
    background: #5b48cf; // TODO: use token
  }

  &:disabled {
    cursor: not-allowed;
    background: #c2c6d3; // TODO: use token
  }

  &.active {
    background: #513fbc; // TODO: use token
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: iconography.$icon-size-m;
  width: 24px;
  height: 24px;

  > svg {
    width: 100%;
    height: 100%;
  }
}

.icon + .text {
  margin-left: 8px;
}

.sizeL {
  height: 44px;
  padding: 10px 12px;
}

.variantSecondary,
.variantTertiary {
  color: #021148; // TODO: use token
  background: #fff;

  &:focus-visible {
    background: #fff;

    &::before {
      border-color: colors.$hyperlink;
    }
  }

  &:hover {
    background: #f1effe; // TODO: use token
  }

  &.active {
    background: #e2d4ff;
  }

  &:disabled {
    color: #c2c6d3;
    background-color: #fff;
  }
}

.variantSecondary {
  &::before {
    border-color: #d7d9e2; // TODO: use token
  }

  &:hover,
  &.active {
    &::before {
      border-color: #9aa0b6; // TODO: use token
    }
  }

  &:disabled::before {
    border-color: #f0f1f4; // TODO: use token
  }
}

.widthStretch {
  width: 100%;
}
</style>
