import { inject, getCurrentInstance } from 'vue';
import type { Plugin } from 'vue';
import { ApiService } from './api-service';
import type { ApiServiceOptions } from './api-service';
import { API_SYMBOL } from './constants';

declare module 'vue' {
  interface ComponentCustomProperties {
    $api: ApiService;
  }
}

let activeApi: ApiService;

export function createApi(options: ApiServiceOptions): Plugin {
  const api = new ApiService(options);

  return {
    install(app) {
      app.provide(API_SYMBOL, api);
      app.config.globalProperties.$api = api;
      activeApi = api;
    },
  };
}

export function useApi(): ApiService {
  const currentInstance = getCurrentInstance();

  const api = currentInstance ? inject(API_SYMBOL) : activeApi;

  if (!api) {
    throw new Error(`useApi was called with no installed Api`);
  }

  return api;
}

export * from './auth';
export * from './transport';
export * from './interfaces';
export * from './api-service';
export * from './store-transfer';
export * from './superset';
export * from './replenishment';
export * from './meilisearch-indexes';
