import { defineStore } from 'pinia';
import { isObject } from 'lodash-es';
import { useApi } from '../../services/api';
import type { StoreTransferSettings, TransportError } from '../../services/api';

export const useStoreTransferSettingsStore = defineStore('store-transfer-settings', {
  state: (): StoreTransferSettings & {
    tripDetails: {
      width: number;
    };
  } => ({
    id: '',
    minimum_service_level: 0,
    depleted_wh_threshold: 0,
    first_allocation_date_threshold: 0,
    planning_horizon: 0,
    time_to_execution: 0,
    minimum_esi_per_product_trip: 0,
    minimum_esi_per_trip: 0,
    left_over: false,
    pull_back_to_wh: false,
    service_level_leftover_threshold: 0,
    leftover_push_over: 0,
    account_id: '',
    is_active: false,
    days: [],
    clusters_qty: 0,
    tripDetails: {
      width: 680,
    },
  }),
  actions: {
    async fetch(): Promise<void> {
      const api = useApi();

      try {
        const { data } = await api.storeTransfer.getSettings();

        this.$patch(data.data);
      } catch (error: unknown) {
        const unknownError = error as TransportError;

        // patch solution for handle case when cluster is not defined on the BE side
        if (
          isObject(unknownError) &&
          unknownError.isTransportError &&
          unknownError.status === 404
        ) {
          this.clear();
          return;
        }
        throw error;
      }
    },
    async clear(): Promise<void> {
      this.$reset();
    },
  },
  persist: {
    paths: ['tripDetails.width'],
  },
});
